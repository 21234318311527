import React, { useState } from "react";
import { HttpDebug } from "../../api";
import { useRecoilValue } from "recoil";
import { actorAtom } from "../../recoil/atoms";

export default function HttpStatsOverlay() {
	const actor = useRecoilValue(actorAtom);
	const [logs] = useState(HttpDebug.latestRequests);
	const [visible, setVisible] = useState<boolean>(false);

	if (!actor?.account?.is_admin) return null;
	if (!visible)
		return (
			<div
				className="devModeHint position-fixed bottom-0 start-0 p-1"
				style={{
					borderTopRightRadius: "0.5rem",
				}}>
				<button
					className="btn btn-danger btn-sm"
					onClick={() => setVisible(true)}>
					HTTP Debug
				</button>
			</div>
		);

	return (
		<div
			className="devModeHint position-fixed bottom-0 top-0 start-0 end-0 bg-dark text-white p-2"
			style={{
				overflow: "auto",
			}}>
			<div className="d-flex justify-content-end">
				<button
					className="btn btn-sm btn-danger"
					onClick={() => setVisible(false)}>
					Sluiten
				</button>
			</div>
			<div className="justify-content-end">
				<h6 className="m-0">
					<span className="badge rounded-pill bg-secondary">
						Http Request Logs
					</span>
				</h6>
			</div>

			<div className="m-0">
				{logs.length === 0 && (
					<div className="text-muted">Geen requests</div>
				)}
				{logs.reverse().map((item) => {
					const isBad = item.status >= 400;

					const headersArray = item.headers?.toString()?.split("\n");

					// const headersObject = item.headers;
					// parse item.headers with type of AxiosRequestHeaders
					// how to do this?

					return (
						<div
							key={item.id}
							className={`border rounded p-2 my-2 border-${
								isBad ? "danger" : "success"
							}`}>
							<div>
								<span
									className={`badge bg-${
										isBad ? "danger" : "success"
									}`}>
									{isBad ? "Error Range" : "OK Range"}
								</span>
							</div>
							<div>
								<small>ID: {item.id}</small>
							</div>
							<div>
								<small>Method: {item.method}</small>
							</div>
							<div>
								<small>
									URL:{" "}
									<a
										className="text-muted"
										href={`${item.host}${item.url}`}>
										{item.host}
										{item.url}
									</a>
								</small>
							</div>
							<div>
								<small>Status: {item.status}</small>
							</div>
							<div>
								<small>
									Status Text: {item.statusText || "N/A"}
								</small>
							</div>
							<div>
								<small>
									Duration:{" "}
									{new Date(item.requestEnd).getTime() -
										new Date(item.requestStart).getTime()}
									ms
								</small>
							</div>
							<div className="statsOverlayHider">
								<small>Headers:</small>
								<small className="text-muted">
									{" "}
									Hover To View
								</small>
								<pre className="HiddenContent">
									{JSON.stringify(
										headersArray,
										// {},
										null,
										2
									) || "N/A"}
								</pre>
							</div>
							<div className="statsOverlayHider">
								<small>Request Body:</small>
								<small className="text-muted">
									{" "}
									Hover To View
								</small>
								<pre className="HiddenContent">
									{JSON.stringify(
										JSON.parse(item?.body || "{}"),
										null,
										2
									) || "N/A"}
								</pre>
							</div>
							<div className="statsOverlayHider">
								<small>Response Body:</small>
								<small className="text-muted">
									{" "}
									Hover To View
								</small>
								<pre className="HiddenContent">
									{JSON.stringify(
										typeof item.response === "string"
											? JSON.parse(item.response) || "{}"
											: item.response || {},
										null,
										2
									) || "N/A"}
								</pre>
							</div>
							<div>
								<small>
									Logged At:{" "}
									{new Date(
										item.requestStart
									).toLocaleTimeString()}
								</small>
							</div>
						</div>
					);
				})}
			</div>
		</div>
	);
}
