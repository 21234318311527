import { DW3_PARTNERS } from "../../config";
import "../../styles/fullContainer.css";

//? FullContainer is a component that is used to center the content of a page
interface IProps {
	children?: React.ReactNode;
}

export default function FullContainer({ children }: IProps) {
	return (
		<div>
			<div className="fullContainerWrapper d-flex justify-content-center align-items-center">
				<div className="fullContainer p-lg-5 p-2 shadow">
					{children}
				</div>
			</div>
			{DW3_PARTNERS.length > 0 && (
				<div className="mt-4">
					<div className="d-flex justify-content-center align-items-center">
						<div className="partnerContainer align-items-center">
							<h5 className="partnerContainerTitle text-center">
								Partners
							</h5>
							<div className="partnerContainerLogos align-items-center">
								{DW3_PARTNERS.map((partner) => (
									<a
										href={partner.url}
										target="_blank"
										rel="noreferrer"
										key={partner.url}
										style={{
											margin: "0 5px",
										}}>
										<img
											src={partner.logo}
											alt={partner.logo}
											style={{
												maxHeight: 50,
											}}
											className="rounded img-fluid"
										/>
									</a>
								))}
							</div>
						</div>
					</div>
					<br />
				</div>
			)}

			<div className="text-center pb-5">
				<h5>Gemaakt door:</h5>
				<p>
					<a href="https://www.linkedin.com/in/gijs-bal-11180322b">
						Gijs Bal
					</a>{" "}
					<a
						href="https://www.youtube.com/watch?v=xvFZjo5PgG0"
						style={{
							color: "var(--font-black)",
							textDecoration: "none",
						}}
						target="blank">
						&
					</a>{" "}
					<a href="https://www.linkedin.com/in/sem-f/">Sem Fuchs</a>
				</p>
			</div>
		</div>
	);
}
