import API, { IApiBareResponse } from ".";
import { IAccount } from "../types/Account";

//? Type definitions
interface IAccountResponse extends IApiBareResponse {
	account: IAccount;
}

interface IAllAccountsResponse extends IApiBareResponse {
	list: IAccount[];
	page: number;
	hasMore: boolean;
}

//? Service class
class AccountService extends API {
	constructor() {
		super(true, "/account");
	}

	//? Get account data
	async getSession() {
		try {
			const response = await this.get<IAccountResponse>("/session");
			return response;
		} catch (error) {
			throw error;
		}
	}

	//? Get all accounts
	async getAllAccounts(page: number, search: string) {
		try {
			const response = await this.get<IAllAccountsResponse>(
				`?page=${page}&q=${search}`
			);
			return response;
		} catch (error) {
			throw error;
		}
	}
	//? account takeover (password reset)
	async sendAccountTakeOver(uid: string) {
		try {
			const response = await this.post<IAccountResponse>(
				`/${uid}/ato`,
				{}
			);
			return response;
		} catch (error) {
			throw error;
		}
	}

	//? activate account function
	async activateAccount(uid: string) {
		try {
			const response = await this.post<IAccountResponse>(
				`/${uid}/activate`,
				{}
			);
			return response;
		} catch (error) {
			throw error;
		}
	}

	//? deactivate account function
	async deactivateAccount(uid: string) {
		try {
			const response = await this.post<IAccountResponse>(
				`/${uid}/deactivate`,
				{}
			);
			return response;
		} catch (error) {
			throw error;
		}
	}

	//? create account function
	async createAccount(nickname: string, email: string) {
		try {
			const response = await this.post<IAccountResponse>(`/`, {
				nickname: nickname,
				email: email,
			});
			return response;
		} catch (error) {
			throw error;
		}
	}

	//? Set account ADMIN
	async toggleAdmin(uid: string) {
		try {
			const response = await this.post<IAccountResponse>(
				`/${uid}/admin`,
				{}
			);
			return response;
		} catch (error) {
			throw error;
		}
	}

	//? Change account password
	async changePassword(password: string, newPassword: string) {
		try {
			const response = await this.post<IAccountResponse>(`/password`, {
				password,
				newPassword,
			});
			return response;
		} catch (error) {
			throw error;
		}
	}
}

//? Export service
const accountService = new AccountService();
export default accountService;
