import API, { IApiBareResponse } from ".";
import { ITheme, IThemeNote } from "../types/Theme";

//? Type definitions
interface INoteBaseResponse extends IApiBareResponse {
	note: IThemeNote;
}

interface IThemeArrayResponse extends IApiBareResponse {
	themes: ITheme[];
}

//? Service class
class NoteService extends API {
	constructor() {
		super(true, "/theme-notes");
	}

	//? Bulk update notes
	async bulkUpdate(sessionId: string, notes: { [note_id: string]: string }) {
		try {
			const response = await this.put<IThemeArrayResponse>(
				`/session/${sessionId}/bulk`,
				{
					notes,
				}
			);
			return response;
		} catch (error) {
			throw error;
		}
	}

	//? Create an initial note
	async create(sessionId: string, themeId: string, note: string) {
		try {
			const response = await this.post<INoteBaseResponse>(
				`/session/${sessionId}/theme/${themeId}`,
				{
					note,
				}
			);
			return response;
		} catch (error) {
			throw error;
		}
	}

	//? Create an initial note
	async update(noteId: string, note: string) {
		try {
			const response = await this.put<INoteBaseResponse>(`/${noteId}`, {
				note,
			});
			return response;
		} catch (error) {
			throw error;
		}
	}
}

//? Export service
const noteService = new NoteService();
export default noteService;
