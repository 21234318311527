import React, { useState, useEffect } from "react";
import LogoTopBar from "../../components/TopBars/LogoTopBar";
import { useNavigate } from "react-router-dom";
import FullContainer from "../../components/layout/fullContainer";
import { ITheme } from "../../types/Theme";
import themeService from "../../api/theme.service";
import { toast } from "react-hot-toast";
import Modal from "../../components/Modal/Modal";
import { Link } from "react-router-dom";
import { isHexDark } from "../../util/hexColor";

export default function ThemeManagement() {
	const navigate = useNavigate();

	//? States
	const [expandedThemeIds, setExpandedThemeIds] = useState<string[]>([]);
	const [editTheme, setEditTheme] = useState<ITheme | null>(null);
	const [themes, setThemes] = useState<ITheme[]>([]);
	const [loading, setLoading] = useState<boolean>(false);
	const [loadingUpdate, setLoadingUpdate] = useState<boolean>(false);

	const getThemes = async () => {
		//? Get all themes without a parent (all top level themes)
		setLoading(true);
		themeService
			.getParentlessList()
			.then((themes) => {
				setThemes(themes.themes);
			})
			.catch((error) => {
				toast.error(error);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const updateTheme = async () => {
		if (!editTheme) return toast.error("Geen thema geselecteerd");

		//? Update theme
		setLoadingUpdate(true);
		themeService
			.update(editTheme?.id, {
				name: editTheme?.name,
				color: editTheme?.extensions?.color,
				content: editTheme?.extensions?.content,
			})
			.then(({ theme }) => {
				toast.success("Thema succesvol aangepast");
				setEditTheme(null);
				//? If theme has a parent, update the parent theme array
				if (theme?.parent_theme_id) {
					setThemes(
						themes.map((t) => {
							if (t.id === theme.parent_theme_id) {
								return {
									...t,
									theme: t.theme?.map((st) => {
										if (st.id === theme?.id) {
											return theme;
										}
										return st;
									}),
								};
							}
							return t;
						}) as ITheme[]
					);
				} else {
					// If theme has no parent, update the top level theme array
					setThemes(
						themes.map((t) => {
							if (t.id === theme.id) return theme;
							return t;
						}) as ITheme[]
					);
				}
			})
			.catch((error) => {
				toast.error(error);
			})
			.finally(() => {
				setLoadingUpdate(false);
			});
	};

	const toggleThemeExpanded = (theme: ITheme) => {
		//? Toggle theme expanded
		if (expandedThemeIds.includes(theme.id)) {
			setExpandedThemeIds(
				expandedThemeIds.filter((id) => id !== theme.id)
			);
		} else {
			setExpandedThemeIds([...expandedThemeIds, theme.id]);
		}
	};

	const deleteTheme = async (theme: ITheme) => {
		setLoadingUpdate(true);

		//? Delete theme
		themeService
			.deleteOne(theme?.id)
			.then(() => {
				toast.success("Thema verwijderd");
				setThemes(themes.filter((t) => t.id !== theme.id));
				setEditTheme(null);
			})
			.catch((error) => {
				toast.error(error);
			})
			.finally(() => {
				setLoadingUpdate(false);
			});
	};

	useEffect(() => {
		getThemes();
	}, []);

	return (
		<div>
			<LogoTopBar
				button={{
					onClick: () => {
						navigate("/app");
					},
					text: "Terug",
					className: "btn-light text-dark",
				}}
			/>
			<Modal
				visible={editTheme !== null}
				title="Pas thema aan"
				confirmText="Confirm"
				cancelText="Cancel"
				onConfirm={updateTheme}
				loading={loadingUpdate}
				onCancel={() => {
					setEditTheme(null);
				}}>
				<>
					<label htmlFor="EditThemeName">Thema Naam</label>
					<input
						type="text"
						name="EditThemeName"
						placeholder="Naam voor het Thema"
						className="form-control mb-2"
						value={editTheme?.name}
						onChange={(e) => {
							setEditTheme({
								...editTheme,
								name: e.target.value,
							} as ITheme);
						}}
					/>
					<label htmlFor="EditThemeContent">Thema Omschrijving</label>
					<textarea
						name="EditThemeContent"
						className="form-control mb-2"
						placeholder="Omschrijving voor het Thema"
						value={editTheme?.extensions?.content}
						onChange={(e) => {
							setEditTheme({
								...editTheme,
								extensions: {
									...editTheme?.extensions,
									content: e.target.value,
								},
							} as ITheme);
						}}></textarea>
					<label htmlFor="EditThemeColor">Thema Kleur</label>
					<input
						type="color"
						name="EditThemeColor"
						className="form-control form-control-color"
						value={editTheme?.extensions?.color}
						onChange={(e) => {
							setEditTheme({
								...editTheme,
								extensions: {
									...editTheme?.extensions,
									color: e.target.value,
								},
							} as ITheme);
						}}
					/>

					<label htmlFor="color-font" className="mt-2">
						Thema Font Kleur
					</label>
					<input
						type="color"
						name="color"
						className="form-control form-control-color"
						value={
							isHexDark(editTheme?.extensions?.color)
								? "#ffffff"
								: "#000000"
						}
						disabled
					/>

					<hr />
					<button
						className="btn btn-danger"
						onClick={() => {
							deleteTheme(editTheme as ITheme);
						}}
						disabled={loadingUpdate}>
						Verwijder Thema
					</button>
					<p className="text-danger">
						<b>Waarschuwing:</b> Dit verwijderd ook alle subthema's!
					</p>
				</>
			</Modal>
			<FullContainer>
				<h3>Thema beheer</h3>
				<Link
					to={"/app/manage/themes/new"}
					className="btn btn-primary btn-sm">
					Nieuw Thema
				</Link>
				<p className="text-muted">
					Klik op een (sub-)thema om deze te bewerken
				</p>
				{loading && <p className="text-muted">Loading...</p>}
				{themes.map((theme) => {
					return (
						<div key={theme.id}>
							<div
								className="card"
								onClick={() => {
									setEditTheme(theme);
								}}
								style={{
									cursor: "pointer",
								}}>
								<div className="card-body">
									<h5 className="card-title">{theme.name}</h5>
									<input
										type="color"
										className="form-control form-control-color"
										disabled
										value={theme.extensions?.color}
									/>
									<p>{theme.theme?.length} subthema's</p>
								</div>
							</div>
							<div className="container mt-2 mb-2">
								{theme.theme?.length > 0 && (
									<div>
										<button
											className="btn btn-secondary"
											onClick={() =>
												toggleThemeExpanded(theme)
											}>
											{expandedThemeIds.includes(theme.id)
												? "Verberg"
												: "Bekijk"}{" "}
											subthema's
										</button>
									</div>
								)}
								{theme.theme?.length > 0 &&
									expandedThemeIds.includes(theme.id) && (
										<>
											{theme.theme.map((subTheme) => {
												return (
													<div
														style={{
															cursor: "pointer",
														}}
														onClick={() => {
															setEditTheme(
																subTheme
															);
														}}
														key={subTheme.id}
														className="card mt-2">
														<div className="card-body">
															<h5 className="card-title">
																{subTheme.name}
															</h5>
															<input
																type="color"
																className="form-control form-control-color"
																disabled
																value={
																	subTheme
																		.extensions
																		?.color
																}
															/>
														</div>
													</div>
												);
											})}
										</>
									)}
							</div>
						</div>
					);
				})}
			</FullContainer>
		</div>
	);
}
