import React, { useState, useEffect } from "react";
import ListView from "../../components/layout/listView";
import useAuth from "../../hooks/useAuth";
import LinkButton from "../../components/utils/LinkButton";
import accountService from "../../api/account.service";
import { IAccount, IAccountType } from "../../types/Account";
import toast from "react-hot-toast";
import "../../styles/accounts.css";
import moment from "moment";
import twofaService from "../../api/mfa.service";
import { useNavigate, useSearchParams } from "react-router-dom";

export default function Users() {
	// ?make hooks
	const { actor } = useAuth(true);
	const [loading, setLoading] = useState(false);
	const [accounts, setAccounts] = useState<IAccount[]>([]);
	const [detailAccount, setDetailAccount] = useState<IAccount | null>(null);
	const [page, setPage] = useState(0);
	const [hasMore, setHasMore] = useState(false);

	const [queryParams, setQueryParams] = useSearchParams();

	const navigate = useNavigate();

	//? function to update accounts list when changes are made
	const _updateAccountsList = (account: IAccount) => {
		let newAccounts = accounts;
		newAccounts = newAccounts.map((item) => {
			if (item.id === account.id) {
				return account;
			} else {
				return item;
			}
		});
		setAccounts(newAccounts);
	};

	//? toggles whether an account has admin status or not
	const toggleAdmin = (account: IAccount) => {
		toast.promise(accountService.toggleAdmin(account.id), {
			loading: "Bezig...",
			success: ({ account: acc }) => {
				setDetailAccount(acc);
				_updateAccountsList(acc);
				return `Account is nu een ${
					acc.is_admin ? "Admin" : "Gebruiker"
				}`;
			},
			error: (error) => error,
		});
	};

	//? Forceer 2fa op een account
	const force2FA = (account: IAccount) => {
		toast.promise(twofaService.activate(account.id), {
			loading: "Bezig...",
			success: () => {
				return `Account 2FA verplicht gemaakt en of gerefreshed!`;
			},
			error: (error) => error,
		});
	};

	//? toggles whether an account has active status or not
	const toggleAccountActive = (account: IAccount) => {
		if (account.type === IAccountType.ACTIVE) {
			toast.promise(accountService.deactivateAccount(account.id), {
				loading: "Bezig...",
				success: ({ account: acc }) => {
					setDetailAccount(acc);
					_updateAccountsList(acc);
					return "Account gedeactiveerd";
				},
				error: (error) => error,
			});
		} else {
			toast.promise(accountService.activateAccount(account.id), {
				loading: "Bezig...",
				success: ({ account: acc }) => {
					setDetailAccount(acc);
					_updateAccountsList(acc);
					return "Account geactiveerd";
				},
				error: (error) => error,
			});
		}
	};

	//? Something fun ig
	const _randomEmoji = React.useCallback(() => {
		const emojis = ["😷", "👻", "👽", "🤖", "👾", "👺", "👹", "👿", "💩"];
		return emojis[Math.floor(Math.random() * emojis.length)];
	}, []);

	//? immediately resets the given account's password
	const sendAccountTakeover = (uid: string) => {
		toast.promise(accountService.sendAccountTakeOver(uid), {
			loading: "Bezig...",
			success: "Wachtwoord gereset!",
			error: (error) => error,
		});
	};

	//? gets the accounts to render the initial accounts list
	//? if fresh is false the new results get appended to current data, otherwise only show new results
	const getAccounts = (fresh: boolean = false, event?: any) => {
		if (event) event.preventDefault();
		setLoading(true);
		accountService
			.getAllAccounts(page, queryParams.get("search") || "")
			.then((data) => {
				//? set the accounts
				if (fresh) setAccounts(data.list);
				else setAccounts((p) => [...p, ...data.list]);
				setPage(data.page);
				setHasMore(data.hasMore);
			})
			.catch((error) => {
				toast.error(error);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	//? useEffect hook for getting the sessions
	useEffect(() => {
		getAccounts();
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<ListView backUri="/app">
			<div className="row w-100 m-0">
				<div className="col-6 p-2">
					<div className="d-flex justify-content-between mb-2">
						<h3>Gebruikers</h3>
						<a
							href="/app/manage/users/create"
							className="btn btn-primary mb-2">
							Nieuw Account
						</a>
					</div>

					<form
						className="input-group w-100 mb-2"
						onSubmit={(event) => getAccounts(true, event)}>
						<input
							type="text"
							className="form-control"
							placeholder="Zoek op naam/email..."
							value={queryParams.get("search") || ""}
							onChange={(e) => {
								setQueryParams({
									search: e.target.value,
								});
							}}
							disabled={loading}
						/>
						<button
							className="btn btn-outline-secondary"
							type="submit"
							disabled={loading}>
							Zoek
						</button>
					</form>

					<table className="table table-hover table-bordered table-responsive table-striped">
						<thead>
							<tr>
								<th scope="col">Naam</th>
								<th scope="col">Email</th>
								<th scope="col">Rol</th>
								<th scope="col">Datum</th>
								<th scope="col">Actie</th>
							</tr>
						</thead>
						<tbody>
							{accounts.map((item) => {
								return (
									<tr key={item.id}>
										<th scope="row">{item.nickname}</th>
										<td>{item.email}</td>
										<td>
											{item?.type === IAccountType.ACTIVE
												? item.is_super
													? "Super Admin"
													: item.is_admin
													? "Admin"
													: "Gebruiker"
												: "Gedeactiveerd"}
										</td>
										<td>
											{moment(item.date).format(
												"MMM Do YYYY, h:mm:ss"
											)}
										</td>
										<td>
											<button
												className="btn btn-dark w-100"
												onClick={() => {
													setDetailAccount(item);
												}}>
												Bekijk
											</button>
										</td>
									</tr>
								);
							})}
						</tbody>
					</table>
					<div className="text-center text-muted">
						{loading ? (
							<p>Aan het laden...</p>
						) : accounts?.length === 0 ? (
							<p>Er zijn geen resultaten</p>
						) : null}
						{hasMore && (
							<button
								className="btn btn-dark"
								onClick={() => {
									getAccounts(false);
								}}
								disabled={loading}>
								Meer Laden
							</button>
						)}
					</div>
				</div>
				<div className="col-6 p-2 mb-2">
					<div
						className="d-flex flex-column justify-content-start w-100"
						style={{
							borderLeft: "3px solid #dee2e6",
							paddingLeft: "1rem",
							top: 10,
							position: "sticky",
						}}>
						{detailAccount ? (
							<div className="user-opened-view">
								<table className="table table-hover table-bordered table-striped">
									<thead
										style={{
											display: "none",
										}}>
										<tr>
											<th scope="col"></th>
											<th scope="col"></th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<th scope="row">Account ID</th>
											<td>{detailAccount.id}</td>
										</tr>
										<tr>
											<th scope="row">Nickname</th>
											<td>{detailAccount.nickname}</td>
										</tr>
										<tr>
											<th scope="row">Rol</th>
											<td>
												{detailAccount.is_super
													? "Super Admin"
													: detailAccount.is_admin
													? "Admin"
													: "Gebruiker"}
											</td>
										</tr>
										<tr>
											<th scope="row">IP Adres</th>
											<td>
												{detailAccount?.current_ip ||
													"Onbekend"}
											</td>
										</tr>
										<tr>
											<th scope="row">Email</th>
											<td>{detailAccount.email}</td>
										</tr>
										<tr>
											<th scope="row">
												Email geverifieerd
											</th>
											<td>
												{detailAccount.email_verified
													? "Ja"
													: "Nee"}
											</td>
										</tr>
										<tr>
											<th scope="row">Account Actief</th>
											<td>
												<input
													type="checkbox"
													className="form-check-input me-2"
													id="detailAccountStatus"
													checked={
														detailAccount.type ===
														IAccountType.ACTIVE
													}
													onClick={() => {
														toggleAccountActive(
															detailAccount
														);
													}}
												/>
											</td>
										</tr>
										<tr>
											<th scope="row">
												Datum Aangemaakt
											</th>
											<td>
												{moment(
													detailAccount.date
												).fromNow()}{" "}
												(
												{moment(
													detailAccount.date
												).format(
													"MMM Do YYYY, h:mm:ss"
												)}
												)
											</td>
										</tr>
										<tr>
											<th scope="row">Datum Aangepast</th>
											<td>
												{moment(
													detailAccount.modified_time
												).fromNow()}{" "}
												(
												{moment(
													detailAccount.modified_time
												).format(
													"MMM Do YYYY, h:mm:ss"
												)}
												)
											</td>
										</tr>
									</tbody>
								</table>
								{/* this is a flex container in case more buttons need to be added */}
								<div className="accountDetailButtonRow w-100">
									<LinkButton
										name="Wachtwoord resetten"
										href={() => {
											sendAccountTakeover(
												detailAccount.id
											);
										}}
									/>
									<LinkButton
										name="Forceer 2FA"
										className="btn btn-warning"
										style={{
											marginLeft: 10,
										}}
										href={() => {
											force2FA(detailAccount);
										}}
									/>
									<LinkButton
										name="Bekijk Sessies"
										className="btn btn-secondary"
										style={{
											marginLeft: 10,
										}}
										href={() => {
											navigate(
												`/app/sessions?uid=${detailAccount.id}`
											);
										}}
									/>
									{actor?.account?.is_super && (
										<LinkButton
											name={
												detailAccount.is_admin
													? "Admin rechten afnemen"
													: "Admin rechten geven"
											}
											className="btn btn-danger"
											style={{
												marginLeft: 10,
											}}
											href={() => {
												toggleAdmin(detailAccount);
											}}
										/>
									)}
								</div>
							</div>
						) : (
							<div className="user-closed-view">
								<h2>{_randomEmoji()}</h2>
								<p>
									Klik op een gebruiker (Bekijk knop) om de
									details te bekijken
								</p>
							</div>
						)}
					</div>
				</div>
			</div>
		</ListView>
	);
}
