import React from "react";
import "../../styles/linkCard.css";

interface IProps {
	href?: string;
	text?: string;
	iconsrc?: string;
	className?: string;
}

export default function LinkCard({ href, text, iconsrc, className }: IProps) {
	return (
		<a
			className={`link-card d-flex flex-column justify-content-between rounded shadow border p-3 ${className}`}
			href={href}>
			<div className="h-75 w-auto icon-wrapper">{iconsrc && <img className="img-fluid h-100" src={iconsrc} alt="icon" />}</div>
			<h6 className="text-center mb-0">{text}</h6>
		</a>
	);
}
