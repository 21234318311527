import React, { useEffect, useState } from "react";
import LogoTopBar from "../../components/TopBars/LogoTopBar";
import FullContainer from "../../components/layout/fullContainer";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { ISession } from "../../types/Session";
import sessionService from "../../api/session.service";
import toast from "react-hot-toast";
import "../../styles/sessionSummary.css";
import Modal from "../../components/Modal/Modal";
import aiService, { AIDisplay } from "../../api/ai.service";

export default function SessionSummary() {
	const navigate = useNavigate();
	const { session_id } = useParams<{
		session_id: string;
	}>();
	const [searchParams] = useSearchParams();
	const [pageLoading, setPageLoading] = useState<boolean>(true);
	const [session, setSession] = useState<ISession | null>(null);
	const [closingSession, setClosingSession] = useState<boolean>(false);
	const [finishModalVisible, setFinishModalVisible] = useState(false);
	const [publicURL, setPublicURL] = useState("");
	const [loadingAIData, setLoadingAIData] = useState<boolean>(true);
	const [loadingAIUsage, setLoadingAIUsage] = useState<boolean>(false);
	const [aiDisplays, setAIDisplays] = useState<{
		[priority_id: string]: AIDisplay;
	}>({});

	const loadAIData = () => {
		setLoadingAIData(true);

		aiService
			.spellcheckPriority(session_id!)
			.then(({ displays }) => {
				setAIDisplays(displays);
			})
			.catch((err) => {
				toast.error(err);
			})
			.finally(() => {
				setLoadingAIData(false);
			});
	};

	const toggleAIUsage = (priority_id: string) => {
		setLoadingAIUsage(true);
		aiService
			.toggleAIDisplayForPriority(priority_id)
			.then(({ displays }) => {
				setAIDisplays({
					...aiDisplays,
					[priority_id]: displays[priority_id],
				});
			})
			.catch((err) => {
				toast.error(err);
			})
			.finally(() => {
				setLoadingAIUsage(false);
			});
	};

	const loadPage = () => {
		sessionService
			.getSession(session_id!)
			.then(({ session }) => {
				setSession(session!);
				loadAIData();
				if (!session?.editable) {
					navigate(`/app/session/${session_id}`);
				}
			})
			.catch((err) => {
				toast.error(err);
			})
			.finally(() => {
				setPageLoading(false);
			});
	};

	const finishSession = async () => {
		setClosingSession(true);
		setFinishModalVisible(true);
		try {
			const followupOption = searchParams.get("followup");
			const { publicURL: url } = await sessionService.finish(
				session_id!,
				followupOption!
			);

			setPublicURL(url);
		} catch (error: any) {
			toast.error(error || "Er is iets misgegaan");
		} finally {
			setClosingSession(false);
		}
	};

	useEffect(() => {
		loadPage();
	}, []); //eslint-disable-line

	return (
		<div>
			{/* export popup */}
			<Modal
				title="Sessie geëxporteerd!"
				confirmText="Naar homepagina"
				cancelText="Terug naar praatplaat"
				loading={closingSession}
				visible={finishModalVisible}
				onCancel={() => {
					navigate(`/app/session/${session_id}`);
				}}
				onConfirm={() => navigate("/app")}>
				<p>De praatplaat is verstuurd naar {session?.company.email}</p>
				<p>
					Met <a href={publicURL}>Deze</a> link kunt U De praatplaat
					delen.
				</p>
				<p className="text-danger fw-bold">
					LET OP: iedereen met deze link zal de praatplaat kunnen
					bekijken
				</p>
				<br />
				<button
					className="btn btn-primary"
					onClick={() => {
						navigator.clipboard.writeText(publicURL);
						toast.success("Link gekopieërd!");
					}}>
					Kopieren
				</button>
			</Modal>
			<LogoTopBar
				button={{
					text: "Terug",
					onClick: () => {
						navigate(`/app/session/${session_id}`);
					},
					className: "btn-light text-dark",
				}}
			/>
			<FullContainer>
				{pageLoading && (
					<h4 className="text-muted text-center">Laden...</h4>
				)}
				{loadingAIData && (
					<h4 className="text-muted text-center">
						Bezig met AI generatie! Dit kan even duren!
					</h4>
				)}
				{!pageLoading && !loadingAIData && (
					<div>
						<h2>Sessie van {session?.company.name}</h2>
						<p>Ondernemer: {session?.company.person_name}</p>
						<p>{session?.company.email}</p>
						<p className="mb-5">
							Afgenomen door: {session?.host_names}
						</p>

						<div className="row mb-5">
							{session?.priorities.map((priority, i) => {
								const display = aiDisplays?.[priority.id];

								return (
									<div className="col-4">
										<div className="rounded p-3 summary-priority border">
											<h3>Prioriteit {i + 1}</h3>
											<p>
												{display?.useSpellcheck
													? display?.spellchecked
													: priority.content}
											</p>
											{display?.useSpellcheck &&
												priority.content && (
													<p className="text-muted mt-2">
														Verbetered door AI
													</p>
												)}
											{!priority.content && (
												<p className="text-muted mt-2">
													Geen tekst ingevuld
												</p>
											)}
											{priority.content && (
												<button
													className="btn btn-primary btn-sm mt-2"
													disabled={loadingAIUsage}
													onClick={() => {
														toggleAIUsage(
															priority.id
														);
													}}>
													{display?.useSpellcheck
														? "Gebruik zelfgeschreven tekst"
														: "Gebruik AI verbetering"}
												</button>
											)}
										</div>
									</div>
								);
							})}
						</div>
						<h5>
							Wenselijk vervolg: {searchParams.get("followup")}
						</h5>
						<p className="mb-3">
							De praatplaat zal worden verstuurd naar{" "}
							{session?.company.email}
						</p>
						<button
							className="btn btn-primary"
							onClick={finishSession}
							disabled={closingSession || loadingAIUsage}>
							Sessie afronden
						</button>
					</div>
				)}
			</FullContainer>
		</div>
	);
}
