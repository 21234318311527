import API, { IApiBareResponse } from ".";
import { ICompany } from "../types/Company";

//? Type definitions
interface ICompaniesResponse extends IApiBareResponse {
	list: ICompany[];
	page: number;
	hasMore: boolean;
}

//? Service class
class CompanyService extends API {
	constructor() {
		super(true, "/company");
	}

	//? Get sessions from user
	async getAllCompanies(q: string, page: number) {
		try {
			const response = await this.get<ICompaniesResponse>(
				`?q=${q}&page=${page}`
			);
			return response;
		} catch (error) {
			throw error;
		}
	}
}

//? Export service
const companyService = new CompanyService();
export default companyService;
