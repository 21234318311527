import React, { useState } from "react";
import FullContainer from "../../components/layout/fullContainer";
import LogoTopBar from "../../components/TopBars/LogoTopBar";
import useAuth from "../../hooks/useAuth";
import toast from "react-hot-toast";
import validator from "validator";
import accountService from "../../api/account.service";
import { useNavigate } from "react-router-dom";

export default function ChangePassword() {
	// define hooks
	const navigate = useNavigate()
	const { actor, setAuth } = useAuth(true);
	const [password, setPassword] = useState<string>("");
	const [newPassword, setNewPassword] = useState<string>("");
	const [newPasswordConfirm, setNewPasswordConfirm] = useState<string>("");
	const [showPassword, setShowPassword] = useState(false);
	const [showNewPassword, setShowNewPassword] = useState(false);
	const [showNewPasswordConfirm, setShowNewPasswordConfirm] = useState(false);
	const [loading, setLoading] = useState(false);

	// back button function
	const _onBack = () => {
		navigate("/app");
	};

	// check if password will be accepted by backend
	const validatePassword = (password: string) => {
		//! When changing validator requirements, they must also be changed in backend
		return validator.isStrongPassword(password, {
			minLength: 8,
			minSymbols: 1,
			minNumbers: 1,
		});
	};

	// get password score for progress bar
	const getPasswordScore = (password: string) => {
		let score = validator.isStrongPassword(password, {
			returnScore: true,
		});
		if (password.length < 8) score = Math.min(score, 25);
		if (password.length < 10) score = Math.min(score, 37);
		return score;
	};

	// group password scores into verbal classifications
	const getPasswordScoreRange = (password: string) => {
		const score = getPasswordScore(password);
		if (score >= 45) return "Erg Sterk";
		if (score >= 30) return "Sterk";
		if (score >= 20) return "Zwak";
		return "Erg Zwak";
	};

	// translate classifications into color classes
	const getPasswordScoreRangeColor = (range: string) => {
		if (range === "Erg Sterk") return "success";
		if (range === "Sterk") return "success";
		if (range === "Zwak") return "warning";
		return "danger";
	};

	// validate inputs and send request to backend, logout when successful
	const changePassword = (event: any) => {
		event.preventDefault()
		if (newPassword !== newPasswordConfirm)
			return toast.error("Wachtwoorden komen niet overeen");

		if (!validatePassword(newPassword)) {
			return toast.error("Nieuw wachtwoord te zwak");
		}

		setLoading(true);

		toast.promise(accountService.changePassword(password, newPassword), {
			loading: "Bezig...",
			success: () => {
				setAuth(null);
				return "Wachtwoord veranderd!";
			},
			error: (error) => {
				setLoading(false);
				return error;
			},
		});
	};

	return (
		<div>
			<LogoTopBar
				button={{
					text: "Terug",
					onClick: _onBack,
					className: "btn-light text-dark",
				}}
			/>
			<FullContainer>
				<form onSubmit={(event) => changePassword(event)}>
				<h1>Wachtwoord veranderen van {actor?.account.nickname}</h1>
				<label htmlFor="passwordInputField" className="form-label">
					Huidig wachtwoord
				</label>

				<div className="input-group mb-4">
					<span
						className="input-group-text"
						onMouseOver={() => {
							setShowPassword(true);
						}}
						onMouseOut={() => {
							setShowPassword(false);
						}}>
						<img src="/eye-fill.svg" alt="icon" />
					</span>
					<input
						type={showPassword ? "text" : "password"}
						className="form-control"
						id="passwordInputField"
						value={password}
						onChange={(e) => setPassword(e.target.value)}
					/>
				</div>

				<label htmlFor="newPasswordInputField" className="form-label">
					Nieuw wachtwoord
				</label>
				<p>
					Wachtwoord moet voldoen aan:
					<ul>
						<li>Minimaal 8 karakters lang</li>
						<li>Één hoofdletter</li>
						<li>Minimaal één cijfer</li>
						<li>Minimaal één symbool</li>
					</ul>
				</p>

				<div className="input-group">
					<span
						className="input-group-text"
						onMouseOver={() => {
							setShowNewPassword(true);
						}}
						onMouseOut={() => {
							setShowNewPassword(false);
						}}>
						<img src="/eye-fill.svg" alt="icon" />
					</span>
					<input
						type={showNewPassword ? "text" : "password"}
						className="form-control "
						id="newPasswordInputField"
						value={newPassword}
						onChange={(e) => setNewPassword(e.target.value)}
					/>
				</div>
				<p>
					Wachtwoord is{" "}
					<span
						className={`fw-bold text-${getPasswordScoreRangeColor(
							getPasswordScoreRange(newPassword)
						)}`}>
						{getPasswordScoreRange(newPassword)}
					</span>
				</p>
				<div className="progress w-25 mb-3">
					<div
						className={`progress-bar bg-${getPasswordScoreRangeColor(
							getPasswordScoreRange(newPassword)
						)}`}
						role="progressbar"
						style={{
							width: `${getPasswordScore(newPassword) * 2}%`,
						}}></div>
				</div>

				<label
					htmlFor="newPasswordConfirmInputField"
					className="form-label">
					Nieuw wachtwoord herhalen{" "}
					<span className="text-danger">
						{newPassword !== newPasswordConfirm &&
							"(wachtwoorden komen niet overeen)"}
					</span>
				</label>
				<div className="input-group mb-3">
					<span
						className="input-group-text"
						onMouseOver={() => {
							setShowNewPasswordConfirm(true);
						}}
						onMouseOut={() => {
							setShowNewPasswordConfirm(false);
						}}>
						<img src="/eye-fill.svg" alt="icon" />
					</span>

					<input
						type={showNewPasswordConfirm ? "text" : "password"}
						className="form-control"
						id="newPasswordConfirmInputField"
						value={newPasswordConfirm}
						onChange={(e) => setNewPasswordConfirm(e.target.value)}
					/>
				</div>

				<button
					type="submit"
					className="btn btn-primary"
					disabled={loading}>
					{loading ? "Bezig..." : "Wachtwoord veranderen"}
				</button>
				</form>
			</FullContainer>
		</div>
	);
}
