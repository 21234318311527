import API, { IApiBareResponse } from ".";
import { ITemplate } from "../types/Template";

//? Type definitions
interface ITemplatesResponse extends IApiBareResponse {
	list: ITemplate[];
	nextPage: number;
	page: number;
	hasMore: boolean;
}

//? Service class
class TemplateService extends API {
	constructor() {
		super(true, "/s");
	}

	//? Get all templates
	async allTemplates(page: number) {
		try {
			const response = await this.get<ITemplatesResponse>(
				`/t0/all?page=${page}`
			);
			return response;
		} catch (error) {
			throw error;
		}
	}

	//? Create template
	async create(followup_options: string[], themeIds: string[]) {
		try {
			const response = await this.post<ITemplate>("/t0/create", {
				followup_options,
				themeIds,
			});
			return response;
		} catch (error) {
			throw error;
		}
	}
}

//? Export service
const templateService = new TemplateService();
export default templateService;
