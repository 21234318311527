import { useRecoilState } from "recoil";
import { actorAtom } from "../recoil/atoms";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import { useEffect } from "react";
import { IAccountAuth } from "../types/Account";

/**
 * useAuth hook
 * @param enforce If true, the user will be redirected to the login page if not logged in
 * @returns The actor and a function to set the actor
 */
export default function useAuth(enforce: boolean = false, publicKey?: string) {
	//? Recoil state
	const [actor, setActor] = useRecoilState(actorAtom);
	const navigate = useNavigate();

	//? Set auth
	const setAuth = (actor: IAccountAuth | null) => {
		if (!actor) {
			//? If the actor is null, remove the actor from the state and remove the token from local storage
			setActor(null);
			localStorage.removeItem("token");
			navigate("/login");
		} else {
			//? If the actor is not null, set the actor in the state and set the token in local storage
			setActor(actor);
			localStorage.setItem("token", actor.token);
		}
	};

	//? Check if the user is logged in
	useEffect(() => {
		if (enforce && !actor) {
			toast.error("Om deze pagina te bekijken moet je ingelogd zijn.");
			navigate("/login");
		}
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	//? Return the actor and the setAuth function
	return {
		actor,
		setAuth,
	};
}
