import React, { useState } from "react";
import { ITheme } from "../../types/Theme";

// ! The template page uses the ExampleTheme component to render themes in the modal

interface IProps {
	item: ITheme;
	sessionId: string;
	editable?: boolean;
	noteContents: { [key: string]: string };
	setNoteContents: (arg: { [key: string]: string }) => void;
}

export function SubTheme({
	item,
	editable,
	noteContents,
	setNoteContents,
}: IProps) {
	const [noteContent] = useState(item.theme_notes?.[0]?.content || "");
	const noteId = item?.theme_notes?.[0]?.id || item?.id;

	return (
		<div
			className="m-2 p-2 rounded"
			style={{
				background: item?.extensions?.color || "grey",
				borderColor: "rgba(0,0,0,0.1)",
				borderWidth: 2,
				borderStyle: "solid",
				minWidth: "200px",
				maxWidth: "400px",
			}}>
			<div>
				<div
					style={{
						backgroundColor: item?.extensions?.color || "grey",
					}}
					className="text-center">
					<h5 className="bg-light text-dark rounded p-1 theme-card-name">
						{item.name}
					</h5>
				</div>
				<div className="text-center">
					<div className="theme-about-title rounded bg-light text-dark mb-1">
						{item?.extensions?.content}
					</div>
				</div>
				{/* Note Area */}
				<label
					htmlFor=""
					className="badge bg-secondary text-light mb-1 mt-2">
					Notitie
				</label>
				<textarea
					disabled={!editable}
					onChange={(e) => {
						setNoteContents({
							...noteContents,
							[noteId]: e.target.value,
						} as any);
					}}
					className="form-control"
					rows={4}
					value={
						noteContents?.[noteId] !== undefined
							? noteContents?.[noteId]
							: noteContent
					}></textarea>
			</div>
		</div>
	);
}
