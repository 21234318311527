import API, { IApiBareResponse } from ".";

//? Service class
class MFAService extends API {
	constructor() {
		super(true, "/2fa");
	}

	//? Login
	async activate(uid: string) {
		try {
			const response = await this.post<IApiBareResponse>(
				`/${uid}/activate`,
				{}
			);
			return response;
		} catch (error) {
			throw error;
		}
	}
}

//? Export service
const twofaService = new MFAService();
export default twofaService;
