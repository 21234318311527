import React from "react";

interface IProps {
	title: string;
	children: React.ReactNode;
	confirmText?: string;
	cancelText?: string;
	loading?: boolean;
	visible: boolean;
	modalVariant?: string;
	onConfirm?: () => void;
	onCancel?: () => void;
}

export default function Modal({
	title,
	children,
	confirmText,
	cancelText,
	loading,
	visible,
	onConfirm,
	onCancel,
	modalVariant,
}: IProps) {
	if (!visible) return null;

	return (
		<div className="e-popup-background">
			<div className="e-popup-shadow">
				<div
					className={`modal fade show ${modalVariant}`}
					id="_CoreModal_App_"
					aria-labelledby="_CoreModal_App_Label"
					aria-modal="true"
					role="dialog"
					style={{
						display: "block",
					}}>
					<div className="modal-dialog">
						<div className="modal-content">
							<div className="modal-header">
								<h1
									className="modal-title fs-5"
									id="_CoreModal_App_Label">
									{title}
								</h1>
								{onCancel && (
									<button
										type="button"
										className="btn-close"
										data-bs-dismiss="modal"
										disabled={loading}
										onClick={onCancel}
										aria-label="Close"></button>
								)}
							</div>
							<div className="modal-body">{children}</div>

							<div className="modal-footer">
								{onCancel && (
									<button
										type="button"
										className="btn btn-secondary"
										data-bs-dismiss="modal"
										disabled={loading}
										onClick={onCancel}>
										{cancelText}
									</button>
								)}
								{confirmText && (
									<button
										type="button"
										disabled={loading}
										onClick={onConfirm}
										className="btn btn-primary">
										{loading ? "Bezig..." : confirmText}
									</button>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
