import React from "react";

interface IProps {
	className?: string;
	id?: string;
	value?: string;
	active?: boolean;
	onChange?: () => void;
}

export default function FollowupOptionRadio({ className, value, active, onChange }: IProps) {
	return (
		<div onClick={onChange}>
			<input
				className="form-check-input me-1"
				type="radio"
				name="followupOption"
				value={value}
				id={value}
				checked={active}
			/>
			<label className="form-check-label" htmlFor={value}>
				<h5 style={{
					fontSize: "1.15rem"
				}}>{value}</h5>
			</label>
		</div>
	);
}
