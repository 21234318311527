import React, { useState, useEffect } from "react";
import { ICompany } from "../../types/Company";
import LogoTopBar from "../../components/TopBars/LogoTopBar";
import { useNavigate, useSearchParams } from "react-router-dom";
import FullContainer from "../../components/layout/fullContainer";
import useAuth from "../../hooks/useAuth";
import companyService from "../../api/company.service";
import { toast } from "react-hot-toast";

export default function Company() {
	useAuth(true);
	const [queryParams, setQueryParams] = useSearchParams();
	const [companiesLoading, setCompaniesLoading] = useState(false);
	const [companies, setCompanies] = useState<ICompany[]>([]);
	const navigate = useNavigate();

	const searchCompanies = (event?: any) => {
		if (event) event.preventDefault();
		setCompaniesLoading(true);
		companyService
			.getAllCompanies(queryParams.get("search") || "", 0)
			.then(({ list }) => {
				setCompanies(list);
			})
			.catch((error) => {
				toast.error(error);
			})
			.finally(() => {
				setCompaniesLoading(false);
			});
	};

	useEffect(() => {
		searchCompanies();
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<div>
			<LogoTopBar
				button={{
					text: "Terug",
					onClick: () => navigate("/app"),
					className: "btn-light text-dark",
				}}
			/>
			<FullContainer>
				<div className="d-flex justify-content-between mb-2">
					<h3>Beheer Bedrijven</h3>
					<form
						className="input-group w-50"
						onSubmit={(event) => searchCompanies(event)}>
						<input
							type="text"
							className="form-control"
							placeholder="Zoek op naam..."
							value={queryParams.get("search") || ""}
							onChange={(e) => {
								setQueryParams({
									search: e.target.value,
								});
							}}
							disabled={companiesLoading}
						/>
						<button
							className="btn btn-outline-secondary"
							type="submit"
							disabled={companiesLoading}>
							Zoek
						</button>
					</form>
				</div>

				<table className="table table-hover table-bordered table-responsive table-striped">
					<thead>
						<tr>
							<th scope="col">Bedrijf</th>
							<th scope="col">Ondernemer</th>
							<th scope="col">Email</th>
							<th scope="col">Actie</th>
						</tr>
					</thead>
					<tbody>
						{companies.map((company) => {
							return (
								<tr key={company.id}>
									<td className="text-wrap-dots">
										{company.name}
									</td>
									<td className="text-wrap-dots">
										{company.person_name}
									</td>
									<td className="text-wrap-dots">
										{company.email}
									</td>
									<td className="row w-100 m-0 px-1">
										<div className="col-6 px-1">
											<button
												className="btn btn-outline-primary w-100"
												onClick={() => {
													navigate(
														`/app/sessions?company_id=${company.id}`
													);
												}}>
												Bekijk Sessies
											</button>
										</div>
										<div className="col-6 px-1">
											<button
												className="btn btn-outline-secondary w-100"
												onClick={() => {
													navigate(
														`/app/new-session?precompany=${btoa(
															JSON.stringify(
																company
															)
														)}`
													);
												}}>
												Start Sessie
											</button>
										</div>
									</td>
								</tr>
							);
						})}
					</tbody>
				</table>
				{companiesLoading && (
					<p className="text-center text-muted">Laden...</p>
				)}
				{companies.length === 0 && !companiesLoading && (
					<p className="text-center text-muted">
						Geen bedrijven gevonden
					</p>
				)}
			</FullContainer>
		</div>
	);
}
