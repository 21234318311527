import React, { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { ISession } from "../../types/Session";
import { ITheme } from "../../types/Theme";
import sessionService from "../../api/session.service";
import NotFoundErrorPage from "../Error/notFoundErrorPage";
import PriorityCard from "../../components/Session/PriorityCard";
import FollowupOptionRadio from "../../components/Session/FollowupOptionRadio";
import "../../styles/session.css";
import useAuth from "../../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import Modal from "../../components/Modal/Modal";
import { APP_URL } from "../../config";
import ThemesSection from "./ThemesSection";
import { SubTheme } from "../../components/Session/Theme";
import noteService from "../../api/note.service";
import { IAvgCompliance } from "../../types/Avg";
import avgService from "../../api/avg.service";

export default function Session() {
	const { actor } = useAuth(false);

	const { session_id } = useParams<{
		session_id: string;
	}>();
	const [searchParams] = useSearchParams();
	const navigate = useNavigate();
	const [loadingSession, setLoadingSession] = useState(true);
	const [session, setSession] = useState<ISession | null>(null);
	const [themes, setThemes] = useState<ITheme[]>([]);
	const [finishModalVisible, setFinishModalVisible] = useState(false);
	const [closingSession] = useState(false);
	const [publicURL] = useState("");
	const [avgCompliance, setAvgCompliance] = useState<IAvgCompliance | null>(
		null
	);
	const [companyData, setCompanyData] = useState<any>({
		ondernemer: "",
		company_email: "",
		uitgevoerdDoor: "",
		bedrijfsnaam: "",
		bedrijfsDoel: "",
	});
	const [followupOption, setFollowupOption] = useState("");
	const [showCompanyInfo, setShowCompanyInfo] = useState(false);
	const [expandTheme, setExpandTheme] = useState<ITheme | null>(null);
	const [noteContents, setNoteContents] = useState<{
		[key: string]: string;
	}>({});
	const [priorityContents, setPriorityContents] = useState<{
		[key: string]: string;
	}>({});
	const [savingAll, setSavingAll] = useState(false);

	const getSession = async () => {
		setLoadingSession(true);
		try {
			const { session: responseSession, themes: themeList } =
				await sessionService.getSession(
					session_id!,
					searchParams?.get("public_key") ?? ""
				);

			setSession(responseSession);
			setFollowupOption(
				responseSession?.extensions?.followupOption ?? ""
			);
			setCompanyData(responseSession?.company ?? ({} as any));
			setThemes(themeList);
		} catch (err: any) {
			toast.error(err);
		} finally {
			setLoadingSession(false);
		}
	};

	const getAvgCompliance = (avgEmail: string) => {
		avgService
			.getCompliance(avgEmail)
			.then((data) => {
				console.log(data);
				setAvgCompliance(data.compliance);
			})
			.catch((err) => {
				toast.error(`AVG Error: ${err}`);
			});
	};

	const bulkSaveNotes = async () => {
		if (Object.keys(noteContents).length === 0) return;
		try {
			const cachedExpandThemeId = expandTheme?.id;
			setExpandTheme(null);
			const { themes: allThemes } = await noteService.bulkUpdate(
				session_id!,
				noteContents
			);

			if (cachedExpandThemeId) {
				const cachedExpandTheme = allThemes.find(
					(item) => item.id === cachedExpandThemeId
				);

				setExpandTheme(cachedExpandTheme ?? null);
			}

			setNoteContents({});
			toast.success("Notities opgeslagen");
		} catch (err: any) {
			toast.error(err);
		}
	};

	const bulkSavePriorities = async () => {
		if (Object.keys(priorityContents).length === 0) return;
		try {
			await sessionService.bulkUpdatePriority(
				session_id!,
				priorityContents
			);
			getSession();
			setPriorityContents({});
			toast.success("Prioriteiten opgeslagen");
		} catch (err: any) {
			toast.error(err);
		}
	};

	const saveAll = async () => {
		if (savingAll) return;
		setSavingAll(true);

		try {
			await bulkSaveNotes();
			await bulkSavePriorities();
			await getSession();
		} catch (err: any) {
			toast.error(err);
		} finally {
			setSavingAll(false);
		}
	};

	useEffect(() => {
		//? Extra "public_token" > searchParams?.get("public_key") ?? ""
		// api request hier
		getSession();
	}, [session_id]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (session?.company?.email) getAvgCompliance(session?.company?.email);
	}, [session?.company?.email]);

	const SaveAllBanner = () => {
		if (
			(Object.keys(noteContents).length > 0 ||
				Object.keys(priorityContents).length > 0) &&
			session?.editable
		)
			return (
				<div className="save-all-sticky">
					<div className="save-all-banner">
						<p>Er zijn nog niet opgeslagen notities/prioriteiten</p>
						<button
							className="btn btn-success"
							onClick={saveAll}
							disabled={savingAll}>
							{savingAll ? "Bezig..." : "Alles opslaan"}
						</button>
					</div>
				</div>
			);

		return null;
	};

	if (closingSession)
		return (
			<h4 className="text-muted text-center mt-4">
				Bezig met afsluiten...
			</h4>
		);

	if (loadingSession)
		return <h4 className="text-muted text-center mt-4">Laden...</h4>;

	if (!session) return <NotFoundErrorPage />;

	return (
		<div className="sessionPageWrapper">
			{savingAll && (
				<div className="save-everything-overlay">
					<div className="seo-inner">
						{/* Loading icon */}
						<div className="spinner-border" role="status">
							<span className="visually-hidden">Loading...</span>
						</div>
						<h4>Bezig met opslaan...</h4>
						<p>Sluit deze pagina niet af!</p>
					</div>
				</div>
			)}

			{/* export popup */}
			<Modal
				title="Sessie geëxporteerd!"
				confirmText="Naar homepagina"
				cancelText="Terug naar praatplaat"
				visible={finishModalVisible}
				onCancel={() => {
					setSession({
						...session,
						editable: false,
					});
					setFinishModalVisible(false);
				}}
				onConfirm={() => navigate("/app")}>
				<p>
					De praatplaat is verstuurd naar {companyData.company_email}
				</p>
				<p>
					Met <a href={publicURL}>Deze</a> link kunt U De praatplaat
					delen.
				</p>
				<p className="text-danger">
					LET OP: iedereen met deze link zal de praatplaat kunnen
					bekijken
				</p>
				<br />
				<button
					className="btn btn-primary"
					onClick={() => {
						navigator.clipboard.writeText(publicURL);
						toast.success("Link gekopieërd!");
					}}>
					Kopieren
				</button>
			</Modal>
			<Modal
				title="Bekijk Thema's"
				cancelText="Sluiten"
				visible={expandTheme !== null}
				modalVariant="modal-xl"
				onCancel={() => {
					setExpandTheme(null);
				}}>
				<div className="sideways-themes-items">
					<SubTheme
						item={expandTheme!}
						sessionId={session.id}
						editable={session?.editable}
						noteContents={noteContents}
						setNoteContents={setNoteContents}
					/>
					{(expandTheme?.theme.length ?? 0) > 0 && (
						<>
							<div className="sideway-theme-divider" />
							<div className="sideway-theme-list">
								{expandTheme?.theme.map((item) => {
									return (
										<SubTheme
											key={item.id}
											item={item}
											sessionId={session.id}
											editable={session?.editable}
											noteContents={noteContents}
											setNoteContents={setNoteContents}
										/>
									);
								})}
							</div>
						</>
					)}
				</div>
			</Modal>
			<Modal
				title="Bedrijfs/Sessie Informatie"
				cancelText="Terug"
				visible={showCompanyInfo}
				onCancel={() => setShowCompanyInfo(false)}>
				<table className="table table-hover table-bordered table-striped">
					<thead
						style={{
							display: "none",
						}}>
						<tr>
							<th scope="col"></th>
							<th scope="col"></th>
						</tr>
					</thead>
					<tbody>
						{actor?.account?.is_admin && (
							<>
								<tr>
									<th scope="row">Sessie ID</th>
									<td>{session?.id}</td>
								</tr>
								<tr>
									<th scope="row">Template ID</th>
									<td>{session?.template_id}</td>
								</tr>
								<tr>
									<th scope="row">Bedrijf ID</th>
									<td>{session?.company?.id}</td>
								</tr>
							</>
						)}
						<tr>
							<th scope="row">Bedrijf</th>
							<td>
								<a
									href={`/app/sessions?company_id=${session?.company?.id}`}>
									{session?.company?.name}
								</a>
							</td>
						</tr>
						<tr>
							<th scope="row">Ondernemer</th>
							<td>{session?.company?.person_name}</td>
						</tr>
						<tr>
							<th scope="row">Ondernemer Email</th>
							<td>
								{session?.company?.email}
								{avgCompliance?.accepted ? (
									<p className="text-success">
										✅ AVG geaccepteerd
									</p>
								) : (
									<p className="text-danger">
										❌ AVG niet geaccepteerd
									</p>
								)}
							</td>
						</tr>
						<tr>
							<th scope="row">Bedrijfs Doel</th>
							<td>{session?.company_goal}</td>
						</tr>
						<tr>
							<th scope="row">Afnemer(s)</th>
							<td>{session?.host_names}</td>
						</tr>
						<tr>
							<th scope="row">DW3 Account</th>
							<td>
								<a
									href={`/app/sessions?uid=${session?.account?.id}`}>
									{session?.account?.nickname}
								</a>
							</td>
						</tr>
					</tbody>
				</table>
			</Modal>

			{/* headerscroll allows scrolling the topbar off the screen */}
			<main>
				{/* wrapper for all themes */}
				{/* Theme UI */}
				<ThemesSection
					session={session}
					themes={themes}
					onCompanyInfoClick={() => setShowCompanyInfo(true)}
					onThemeExpand={(theme) => setExpandTheme(theme)}
					noteContents={noteContents}
				/>
				{/* row for priority inputs and followup/export box */}
				<footer className="sessionPageBottomRow row m-0 ps-0">
					{/* priorities */}
					<div className="prioritiesContainer p-2 pb-0 pe-0 d-flex col-sm-6 col-lg-8 col-xl-9 overflow-auto">
						{session.priorities?.map((item) => {
							return (
								<PriorityCard
									key={item.id}
									item={item}
									priorityContents={priorityContents}
									setPriorityContents={setPriorityContents}
									editable={session?.editable}
								/>
							);
						})}
					</div>
					{/* box for followup options and export button */}
					<div className="p-2 pb-0 col-sm-6 col-lg-4 col-xl-3">
						<div
							className="followupOptionsContainer h-100 p-3 rounded d-flex flex-column"
							style={{
								maxHeight: 245,
								minHeight: 245,
							}}>
							<h3>Wenselijk Vervolg:</h3>
							<div className="followupOptionsList">
								{session.template?.followup_options.map(
									(item: string) => {
										return (
											<FollowupOptionRadio
												key={item}
												value={item}
												active={followupOption === item}
												onChange={() => {
													if (session?.editable)
														setFollowupOption(item);
												}}
											/>
										);
									}
								)}
							</div>
							<div className="buttonRow mt-auto">
								{session.editable && (
									<button
										className="btn btn-primary me-3"
										disabled={savingAll}
										onClick={() => {
											if (!followupOption)
												return toast.error(
													"Geen vervolgoptie ingevuld"
												);
											navigate(
												`/app/session/${session.id}/summary?followup=${followupOption}`
											);
										}}>
										Afronden
									</button>
								)}
								{!session.editable && (
									<div>
										<button
											className="btn btn-primary mb-2"
											onClick={() => {
												navigator.clipboard.writeText(
													publicURL ||
														`${APP_URL}/app/session/${session_id}?public_key=${session?.extensions?.public_key}`
												);
												toast.success(
													"Link gekopieërd!"
												);
											}}>
											Deellink Kopieren
										</button>
									</div>
								)}
								<button
									className="btn btn-primary"
									onClick={() => {
										navigate("/app");
									}}>
									Terug
								</button>
							</div>
						</div>
					</div>
				</footer>
			</main>
			<SaveAllBanner />
		</div>
	);
}
