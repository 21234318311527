import React from "react";
import useAuth from "../../hooks/useAuth";
import { Link } from "react-router-dom";

//? LinkButton is a component that is used to display a button that links to a page
interface LinkProps {
	name?: string;
	href?: string | (() => void);
	requireAdmin?: boolean;
	className?: string;
	style?: React.CSSProperties;
}

export default function LinkButton({
	name,
	href,
	requireAdmin,
	className,
	style,
}: LinkProps) {
	const { actor } = useAuth(true);

	if (requireAdmin && !actor?.account.is_admin) return null;

	return typeof href === "function" ? (
		<button
			className={`btn btn-primary ${className}`}
			style={style}
			onClick={(e) => {
				e.preventDefault();
				href();
			}}>
			{name}
		</button>
	) : (
		<Link
			className={`btn btn-primary ${className}`}
			style={style}
			to={href as string}>
			{name}
		</Link>
	);
}
