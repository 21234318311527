import API, { IApiBareResponse } from ".";

//? Interfaces
export interface AIDisplay {
	useSpellcheck: boolean;
	spellchecked: string;
}

interface ISpellCheckResponse extends IApiBareResponse {
	displays: {
		[priority_id: string]: AIDisplay;
	};
}

//? Service class
class AIService extends API {
	constructor() {
		super(true, "/ai");
	}

	//? Spellcheck priority
	async spellcheckPriority(session_id: string) {
		try {
			const response = await this.post<ISpellCheckResponse>(
				`/spell-check/sessions/${session_id}/priorities`,
				{}
			);
			return response;
		} catch (error) {
			throw error;
		}
	}

	//? Toggle AI usage
	async toggleAIDisplayForPriority(priority_id: string) {
		try {
			const response = await this.post<ISpellCheckResponse>(
				`/toggle-human-input/priority/${priority_id}`,
				{}
			);
			return response;
		} catch (error) {
			throw error;
		}
	}
}

//? Export service
const aiService = new AIService();
export default aiService;
