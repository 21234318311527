import React, { useState } from "react";
import FullContainer from "../../components/layout/fullContainer";
import useAuth from "../../hooks/useAuth";
import "../../styles/home.css";
import LogoTopBar from "../../components/TopBars/LogoTopBar";
import { useNavigate } from "react-router-dom";
import LinkCard from "../../components/Homepage/LinkCard";
import Modal from "../../components/Modal/Modal";

export default function Home() {
	const { actor, setAuth } = useAuth(true);
	const navigate = useNavigate();
	const [logoutModalVisible, setLogoutModalVisible] = useState(false);

	const topTitleText = `DW3 ${actor?.account.is_admin ? "(Admin)" : ""}`;
	const bottomTitleText = `Welkom, ${actor?.account?.nickname}`;

	return (
		<div>
			<Modal
				title="Uitloggen"
				confirmText="Log uit"
				onConfirm={() => setAuth(null)}
				cancelText="Annuleren"
				onCancel={() => setLogoutModalVisible(false)}
				visible={logoutModalVisible}>Weet u zeker dat u wilt uitloggen?</Modal>
			<LogoTopBar
				button={{
					onClick: () => setLogoutModalVisible(true),
					text: "Uitloggen",
				}}
				secondButton={{
					onClick: () => navigate("/app/changepassword"),
					text: "Wachtwoord veranderen",
					className: "me-2",
				}}
			/>
			<FullContainer>
				<div className="text-center">
					<h1>{topTitleText}</h1>
					<h2 className="mb-5">{bottomTitleText}</h2>
					<div className="row px-5 mb-5">
						<div className="col-sm-4 col-lg-3 col-xl-2 d-flex justify-content-center ms-auto">
							<LinkCard
								text="Oudere sessies bekijken"
								href={`/app/sessions?uid=${actor?.uid}`}
								iconsrc="/history-svgrepo-com.svg"
							/>
						</div>
						<div className="col-sm-4 col-lg-3 col-xl-2 d-flex justify-content-center">
							<LinkCard
								text="Sessie starten"
								href="/app/new-session"
								iconsrc="/plus-large-svgrepo-com.svg"
							/>
						</div>
						<div className="col-sm-4 col-lg-3 col-xl-2 d-flex justify-content-center me-auto">
							<LinkCard
								text="Bedrijven bekijken"
								href="/app/manage/companies"
								iconsrc="/company-svgrepo-com.svg"
							/>
						</div>
					</div>
					{actor?.account.is_admin && (
						<>
							<h2>Admin-only Pagina's</h2>
							<div className="row d-flex-justify-content-center px-5 mb-5">
								<div className="col-sm-6 col-lg-3 col-xl-2 d-flex justify-content-center mb-3 ms-lg-auto">
									<LinkCard
										text="Gebruikers beheren"
										href="/app/manage/users"
										iconsrc="/users-svgrepo-com.svg"
									/>
								</div>
								<div className="col-sm-6 col-lg-3 col-xl-2 d-flex justify-content-center mb-3">
									<LinkCard
										text="Alle sessies bekijken"
										href={`/app/sessions?uid=admin`}
										iconsrc="/clipboard-text-svgrepo-com.svg"
									/>
								</div>
								<div className="col-sm-6 col-lg-3 col-xl-2 d-flex justify-content-center mb-3">
									<LinkCard
										text="Templates beheren"
										href="/app/templates"
										iconsrc="/mindmap-svgrepo-com.svg"
									/>
								</div>
								<div className="col-sm-6 col-lg-3 col-xl-2 d-flex justify-content-center mb-3 me-lg-auto">
									<LinkCard
										text="Thema's beheren"
										href="/app/manage/themes"
										iconsrc="/pen-square-svgrepo-com.svg"
									/>
								</div>
							</div>
						</>
					)}
				</div>
			</FullContainer>
		</div>
	);
}
