//? Main Config File

import { ServiceHosts } from "./types/System";

//? SERVICE_URL is the base URL of the API server
export const IS_DEV_SERVER = false; //? Check if the server is running on localhost

//? Service hosts
const SERVICE_HOSTS = {
	[ServiceHosts.PROD]: "https://dw3-api.tenicate.com",
	[ServiceHosts.DEV]: "http://localhost:3002",
};

const APP_LINKS = {
	[ServiceHosts.PROD]: "https://app.dw3.nl",
	[ServiceHosts.DEV]: "http://localhost:3000",
};

//? Service URL
export const SERVICE_URL = IS_DEV_SERVER
	? SERVICE_HOSTS[ServiceHosts.DEV]
	: SERVICE_HOSTS[ServiceHosts.PROD];
export const APP_URL = IS_DEV_SERVER
	? APP_LINKS[ServiceHosts.DEV]
	: APP_LINKS[ServiceHosts.PROD];
export const API_VERSION = "v1"; //? API version (will be added to the URL)

//? Partner List Data
export const DW3_PARTNERS = [
	{
		logo: "https://media.tenor.com/CYsBhq56isgAAAAC/pog-poggers.gif",
		url: "https://enact.gg",
	},
	{
		logo: "https://media.tenor.com/W20GaE2tI1QAAAAd/cat-calico-cat.gif",
		url: "https://aminoapps.com",
	},
	{
		logo: "https://media.tenor.com/6XVdR-322cQAAAAd/ajr-bummerland.gif",
		url: "https://aventus.nl",
	},
];

