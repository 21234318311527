import React from "react";
import { API_VERSION, IS_DEV_SERVER, SERVICE_URL } from "../../config";

export default function DeveloperOverlay() {
	if (!IS_DEV_SERVER) return null;

	return (
		<div className="devModeHint position-fixed bottom-0 end-0 bg-danger text-white p-2 text-center"
		style={{
			// sticky
		}}
			>
			<p className="m-0">
				DEV MODE
			</p>
            <small className="m-0 extraDevInfo">
                {SERVICE_URL} ({API_VERSION})
            </small>
		</div>
	);
}
