import axios, { AxiosResponse } from "axios";
import { SERVICE_URL, API_VERSION } from "../config";
import { IHttpDebug, IHttpDebugRequest } from "../types/HttpDebug";

export const HttpDebug: IHttpDebug = {
	latestRequests: [],
};

//? Create axios instance
const api = axios.create({
	baseURL: SERVICE_URL,
});

//? intercept to add session token
api.interceptors.request.use((config) => {
	const token = localStorage.getItem("token");
	config.headers["req-start-time"] = Date.now();
	if (token) {
		config.headers["Authorization"] = `Bearer ${token}`;
	}
	return config;
});

function registerResponseData(response: AxiosResponse<any, any>) {
	const requestEnd = new Date();
	const requestStart = new Date(
		parseInt(response.config.headers["req-start-time"])
	);

	HttpDebug.latestRequests.push({
		id: Math.random().toString(36),
		host: response.config.baseURL,
		url: response.config.url,
		method: response.config.method?.toUpperCase(),
		headers: response.config.headers,
		body: response.config.data,
		response: response.data || response?.request?.response,
		status: response.status || response?.request?.status,
		statusText: response.statusText || response?.request?.statusText,
		requestStart: requestStart,
		requestEnd: requestEnd,
		createdAt: new Date(),
	} as IHttpDebugRequest);
}

//? intercept to log any request, errors and responses
api.interceptors.response.use(
	(config) => {
		registerResponseData(config);

		return config;
	},
	(error) => {
		console.log(error)
		registerResponseData(error);

		return Promise.reject(error);
	}
);

//? API bare response interface
export interface IApiBareResponse {
	status: number;
	message: string;
	// other fields can be extended
}

export default class API {
	private root: string;

	//? Constructor
	constructor(useVersion: boolean, serviceRoot: string) {
		this.root = (useVersion ? `/${API_VERSION}` : "") + serviceRoot;
	}

	//? Parse API-SERVER errors
	public parseError(error: any, bareResponse: boolean = false) {
		const errData = error?.response?.data;

		//? If there's a JWT error (expireSession key) from server, log user out forcefully
		if (errData?.expireSession) {
			localStorage.removeItem("token");
			localStorage.removeItem("actor");
			window.location.reload();
		}

		//? If bareResponse is true, return the error data as is
		if (bareResponse) return errData as any;

		//? If there's no error data, return a generic error message
		let message =
			errData?.message ||
			error?.message ||
			"Er is een onbekende fout opgetreden.";

		//? If error data is a string, return that string as message
		if (typeof errData === "string") {
			message = errData;
		}

		//? If error data is an HTML file, return the generic error message
		if (error?.response?.headers["content-type"]?.includes("text/html")) {
			message = "Server is niet bereikbaar.";
		}

		return message;
	}

	//? Parse path
	private parsePath(path: string) {
		return path?.startsWith("/") ? path.slice(1) : path;
	}

	//? Custom API methods
	/***
	 * @param path The path to the API endpoint
	 * @param bareResponse If true, the error response will be returned as is
	 * @returns The response data
	 * @throws The error response
	 * @note Custom types can be passed as generic type
	 * */
	protected async get<T>(
		path: string,
		bareResponse: boolean = false
	): Promise<T> {
		try {
			const response = await api.get<T>(
				`${this.root}/${this.parsePath(path)}`
			);
			return response.data;
		} catch (error) {
			throw this.parseError(error, bareResponse);
		}
	}

	//? Custom API methods
	/***
	 * @param path The path to the API endpoint
	 * @param body The body to send to the API endpoint
	 * @param bareResponse If true, the error response will be returned as is
	 * @returns The response data
	 * @throws The error response
	 * @note Custom types can be passed as generic type
	 * */
	protected async post<T>(
		path: string,
		body: any,
		bareResponse: boolean = false
	): Promise<T> {
		try {
			const response = await api.post<T>(
				`${this.root}/${this.parsePath(path)}`,
				body
			);
			return response.data;
		} catch (error) {
			throw this.parseError(error, bareResponse);
		}
	}

	/***
	 * @param path The path to the API endpoint
	 * @param body The body to send to the API endpoint
	 * @param bareResponse If true, the error response will be returned as is
	 * @returns The response data
	 * @throws The error response
	 * @note Custom types can be passed as generic type
	 * */
	protected async put<T>(
		path: string,
		body: any,
		bareResponse: boolean = false
	): Promise<T> {
		try {
			const response = await api.put<T>(
				`${this.root}/${this.parsePath(path)}`,
				body
			);
			return response.data;
		} catch (error) {
			throw this.parseError(error, bareResponse);
		}
	}

	/***
	 * @param path The path to the API endpoint
	 * @param bareResponse If true, the error response will be returned as is
	 * @returns The response data
	 * @throws The error response
	 * @note Custom types can be passed as generic type
	 * */
	protected async delete<T>(
		path: string,
		bareResponse: boolean = false
	): Promise<T> {
		try {
			const response = await api.delete<T>(
				`${this.root}/${this.parsePath(path)}`
			);
			return response.data;
		} catch (error) {
			throw this.parseError(error, bareResponse);
		}
	}
}
