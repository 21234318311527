import React, { useState, useEffect } from "react";
import LogoTopBar from "../../components/TopBars/LogoTopBar";
import { useNavigate } from "react-router-dom";
import FullContainer from "../../components/layout/fullContainer";
import { ITheme } from "../../types/Theme";
import themeService from "../../api/theme.service";
import { toast } from "react-hot-toast";
import templateService from "../../api/template.service";

export default function CreateTemplate() {
	const navigate = useNavigate();

	//? States
	const [themes, setThemes] = useState<ITheme[]>([]);
	const [loading, setLoading] = useState<boolean>(false);
	//? New template states
	const [themeIds, setThemeIds] = useState<string[]>([]);
	const [followUps, setFollowUps] = useState<string[]>([]);
	const [newFollowUp, setNewFollowUp] = useState<string>("");
	const [loadingPost, setLoadingPost] = useState<boolean>(false);

	const getThemes = async () => {
		//? Get all themes without a parent (all top level themes)
		setLoading(true);
		themeService
			.getParentlessList()
			.then((themes) => {
				setThemes(themes.themes);
			})
			.catch((error) => {
				toast.error(error);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const postTemplate = async () => {
		setLoadingPost(true);
		toast
			.promise(templateService.create(followUps, themeIds), {
				loading: "Template wordt aangemaakt",
				success: (data) => {
					navigate("/app/templates");
					return "Template aangemaakt, oudere versies kunnen niet meer worden gebruikt";
				},
				error: (error) => {
					return error;
				},
			})
			.catch((error) => {})
			.finally(() => {
				setLoadingPost(false);
			});
	};

	useEffect(() => {
		getThemes();
	}, []);

	return (
		<div>
			<LogoTopBar
				button={{
					onClick: () => {
						navigate("/app/templates");
					},
					text: "Terug",
					className: "btn-light text-dark",
				}}
			/>
			<FullContainer>
				<h3>Nieuwe Thema</h3>
				<p className="text-muted">
					Selecteer thema's om een template te maken
				</p>
				<label htmlFor="parentTheme">Selecteer Thema's</label>
				{loading ? (
					<p className="text-muted" id="parentTheme">
						Loading...
					</p>
				) : (
					<select
						name="parentTheme"
						className="form-control"
						multiple
						onChange={(e) => {
							const options = e.target.options;

							const selectedOptions = [];

							for (let i = 0; i < options.length; i++) {
								if (options[i].selected) {
									selectedOptions.push(options[i].value);
								}
							}

							setThemeIds(selectedOptions);
						}}>
						{themes.map((theme) => {
							return (
								<option
									value={theme.id}
									selected={themeIds.includes(theme.id)}>
									{theme.name}
								</option>
							);
						})}
					</select>
				)}
				<p>
					<small className="text-muted">
						Ctrl+click om meerdere thema's te selecteren
					</small>
				</p>

				<label htmlFor="followUps" className="mt-2">
					Maak follow ups
				</label>
				{followUps.map((followUp) => {
					return (
						<div
							key={followUp}
							className="d-flex justify-content-between align-items-center mt-2 input-group">
							<input
								className="form-control"
								value={followUp}
								disabled
							/>
							<button
								className="btn btn-danger"
								onClick={() => {
									setFollowUps((p) =>
										p.filter((item) => item !== followUp)
									);
								}}>
								Verwijder
							</button>
						</div>
					);
				})}
				{followUps.length === 0 && (
					<p className="text-muted mt-2 mb-2">Geen follow ups</p>
				)}
				<div className="d-flex justify-content-between align-items-center mt-2 mb-2 input-group">
					<input
						className="form-control"
						value={newFollowUp}
						onChange={(e) => {
							setNewFollowUp(e.target.value);
						}}
					/>
					<button
						className="btn btn-primary"
						onClick={() => {
							if (followUps.length >= 10)
								return toast.error("Maximaal 10 follow ups");

							setFollowUps((p) => [...p, newFollowUp]);
							setNewFollowUp("");
						}}>
						Opslaan
					</button>
				</div>

				<button
					className="btn btn-primary mt-2"
					disabled={loadingPost}
					onClick={postTemplate}>
					{loadingPost ? "Bezig..." : "Opslaan"}
				</button>
			</FullContainer>
		</div>
	);
}
