import React, { useState } from "react";
import { ITheme } from "../../types/Theme";

// ! The session page uses the SubTheme component to render themes in the modal

interface IProps {
	item: ITheme;
}

export function ExampleTheme({ item }: IProps) {
	const [expanded, setExpanded] = useState(false);

	return (
		<div
			className="m-2 p-2 rounded"
			style={{
				background: item?.extensions?.color || "grey",
				borderColor: "rgba(0,0,0,0.1)",
				borderWidth: 2,
				borderStyle: "solid",
				minWidth: "200px",
				maxWidth: "400px",
			}}>
			<div>
				<div
					onClick={() => {
						setExpanded(!expanded);
					}}
					style={{
						backgroundColor: item?.extensions?.color || "grey",
					}}
					className="text-center">
					<h5 className="bg-light text-dark rounded p-1 theme-card-name">{item.name}</h5>
				</div>
				<div className="text-center">
					<div className="theme-about-title rounded bg-light text-dark mb-1">
						{item?.extensions?.content}
					</div>
				</div>
				{/* {editable && (
					<button
						className="btn btn-primary btn-sm mt-2"
						disabled={saving}
						onClick={saveNote}>
						{saving ? "Bezig..." : "Opslaan"}
					</button>
				)} */}
			</div>
		</div >
	);
}
