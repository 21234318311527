export interface IAccountAuth {
	uid: string;
	token: string;
	account: IAccount;
}

export enum IAccountType {
	ACTIVE = "ACTIVE",
	PENDING = "PENDING",
	INACTIVE = "INACTIVE",
}

export interface IAccount {
	id: string;
	nickname: string;
	email?: string;
	current_ip?: string;
	type: IAccountType;
	email_verified: boolean;
	is_admin: boolean;
	is_super: boolean;
	date: Date;
	modified_time: Date;
	extensions: any;
}
