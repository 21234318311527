import API, { IApiBareResponse } from ".";
import { ITheme } from "../types/Theme";

//? Type definitions
interface IThemeListResponse extends IApiBareResponse {
	themes: ITheme[];
}

interface IThemeResponse extends IApiBareResponse {
	theme: ITheme;
}

//? Service class
class ThemeService extends API {
	constructor() {
		super(true, "/theme");
	}

	//? Get parentless list
	async getParentlessList() {
		try {
			const response = await this.get<IThemeListResponse>("/parentless");
			return response;
		} catch (error) {
			throw error;
		}
	}

	//? Get themes from template id
	async getByTemplate(template_id: number) {
		try {
			const response = await this.get<IThemeListResponse>(
				`/template-themes/${template_id}`
			);
			return response;
		} catch (error) {
			throw error;
		}
	}

	async deleteOne(themeId: string) {
		try {
			const response = await this.delete<IThemeResponse>(`/${themeId}`);
			return response;
		} catch (error) {
			throw error;
		}
	}

	/**
	 * Update theme
	 * @param themeId Theme id
	 * @param data Theme data
	 * @returns IThemeResponse
	 */
	async update(
		themeId: string,
		data: {
			color: string;
			name: string;
			content: string;
		}
	) {
		try {
			const response = await this.put<IThemeResponse>(
				`/${themeId}`,
				data
			);
			return response;
		} catch (error) {
			throw error;
		}
	}

	async create(
		parent_id: string | null | undefined,
		name: string,
		content: string,
		color: string
	) {
		try {
			const response = await this.post<IThemeResponse>("/", {
				parent_id,
				name,
				content,
				color,
			});
			return response;
		} catch (error) {
			throw error;
		}
	}
}

//? Export service
const themeService = new ThemeService();
export default themeService;
