import { atom, AtomEffect } from "recoil";
import { IAccountAuth } from "../types/Account";

//? Atom effect to persist the atom in local storage
const persist =
	<T>(key: string): AtomEffect<T> =>
	({ setSelf, onSet }) => {
		const loadPersisted = async () => {
			const savedValue = localStorage.getItem(key);

			if (savedValue !== null) setSelf(JSON.parse(savedValue));
		};

		loadPersisted();

		onSet((newValue) => {
			localStorage.setItem(key, JSON.stringify(newValue));
		});
	};

//? Actor atom
export const actorAtom = atom<null | IAccountAuth>({
	key: "actor",
	default: null,
	// Add persistence
	effects: [persist<IAccountAuth | null>("actor")],
});
