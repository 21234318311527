import React, { useEffect, useState } from "react";
import FullContainer from "../../components/layout/fullContainer";
import useAuth from "../../hooks/useAuth";
import authService from "../../api/auth.service";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import LogoTopBar from "../../components/TopBars/LogoTopBar";

//? Login page
export default function Login() {
	//? useAuth hook for getting the actor and setAuth function
	const navigate = useNavigate();
	const { actor, setAuth } = useAuth();

	//? useState hook for the email, twofaCode and password
	const [email, setEmail] = useState<string>("");
	const [twofaCode, setTwofaCode] = useState<string>("");
	const [password, setPassword] = useState<string>("");
	const [loading, setLoading] = useState<boolean>(false);

	useEffect(() => {
		if (actor?.token) {
			toast.success("U bent al ingelogd");
			navigate("/app");
		}
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	//? login function
	const login = (event:any) => {
		event.preventDefault()
		setLoading(true);
		authService
			.login(email, password, twofaCode)
			.then((response) => {
				toast.success("Login succesvol");
				//? set the auth state
				setAuth({
					token: response.token,
					uid: response.account.id,
					account: response.account,
				});
				navigate("/app");
			})
			.catch((error) => {
				toast.error(authService.parseError(error));
			})
			.finally(() => {
				setLoading(false);
			});
	};

	return (
		<div>
			<LogoTopBar />
			<FullContainer>
				<form onSubmit={(event) => login(event)}>
					{/* Login page using bootstrap*/}
					<h3>Login</h3>
					{/* Email Field */}
					<div className="mb-3">
						<label
							htmlFor="exampleInputEmail1"
							className="form-label">
							Email address
						</label>
						<input
							type="email"
							className="form-control"
							id="exampleInputEmail1"
							value={email}
							onChange={(e) => setEmail(e.target.value)}
						/>
					</div>
					{/* Password Field */}
					<div className="mb-3">
						<label
							htmlFor="exampleInputPassword1"
							className="form-label">
							Password
						</label>
						<input
							type="password"
							className="form-control"
							id="exampleInputPassword1"
							aria-describedby="passwordHelpBlock"
							value={password}
							onChange={(e) => setPassword(e.target.value)}
						/>
						<div id="passwordHelpBlock" className="form-text">
							Als u uw wachtwoord/email bent vergeten, contacteer
							dan een beheerder.
						</div>
					</div>
					{/* 2FA Field */}
					<div className="mb-3">
						<label
							htmlFor="exampleInputPassword1"
							className="form-label">
							2FA Code <small>(Optioneel)</small>
						</label>
						<input
							type="text"
							className="form-control"
							id="exampleInputPassword1"
							aria-describedby="passwordHelpBlock"
							value={twofaCode}
							onChange={(e) => setTwofaCode(e.target.value)}
						/>
						<div id="passwordHelpBlock" className="form-text">
							Vul hier de code in die je op je telefoon krijgt via
							uw Authenticator app.
						</div>
					</div>
					{/* Login button */}
					<button
						type="submit"
						className="btn btn-primary"
						disabled={loading}>
						Login
					</button>
				</form>
			</FullContainer>
		</div>
	);
}
