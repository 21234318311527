import React from "react";
import LogoTopBar from "../../components/TopBars/LogoTopBar";
import FullContainer from "../../components/layout/fullContainer";

export default function NotFoundErrorPage() {
	return (
		<div>
			<LogoTopBar />
			<FullContainer>
				<h1 className="text-center">Error 404</h1>
				<h4 className="text-center text-muted">Pagina niet gevonden</h4>
			</FullContainer>
		</div>
	);
}
