import React, { useEffect, useState } from "react";
import FullContainer from "../../components/layout/fullContainer";
import sessionService from "../../api/session.service";
import companyService from "../../api/company.service";
import Modal from "../../components/Modal/Modal";
import useAuth from "../../hooks/useAuth";
import { ICompany } from "../../types/Company";
import toast from "react-hot-toast";
import "../../styles/companyDataForm.css";
import LogoTopBar from "../../components/TopBars/LogoTopBar";
import { useNavigate, useSearchParams } from "react-router-dom";
import templateService from "../../api/template.service";

export default function CompanyDataForm() {
	// initialize hooks
	useAuth(false);
	const navigate = useNavigate();
	const [queryParams] = useSearchParams();
	const [loadingTemplates, setLoadingTemplates] = useState(true);
	const [hasActiveTemplates, setHasActiveTemplates] = useState(false);
	const [companiesLoading, setCompaniesLoading] = useState(false);
	const [companies, setCompanies] = useState<ICompany[]>([]);
	const [companyId, setCompanyId] = useState<string | null>(null);
	const [companyData, setCompanyData] = useState({
		person_name: "",
		email: "",
		name: "",
		goal: "",
		host_names: "",
	});
	const [companyModalOpen, setCompanyModalOpen] = useState(false);
	const [searchQuery, setSearchQuery] = useState("");
	// Privacy
	const [avgChecked, setAvgChecked] = useState(false);

	// opens the company selection modal
	const openCompanyModal = () => {
		setCompanyModalOpen(true);
		searchCompanies();
	};

	// searches companies to display in a table
	const searchCompanies = () => {
		setCompaniesLoading(true);
		toast.promise(companyService.getAllCompanies(searchQuery, 0), {
			loading: "Bedrijven aan het ophalen...",
			success: ({ list }) => {
				setCompanies(list);
				setCompaniesLoading(false);
				return "Bedrijven opgehaald";
			},
			error: (error) => {
				setCompaniesLoading(false);
				return error;
			},
		});
	};

	// selects a company, and disables the input fields for changing company information
	const selectCompany = (company: ICompany | null) => {
		setCompanyId(company?.id || null);
		setCompanyData({
			...companyData,
			name: company?.name || "",
			person_name: company?.person_name || "",
			email: company?.email || "",
		});
	};

	const submitForm = (event: any) => {
		event.preventDefault();

		if (!avgChecked)
			return toast.error("Er moet akkoord worden gegaan met de AVG");

		const body = {
			company_id: companyId,
			goal: companyData.goal,
			host_names: companyData.host_names,
			newCompany: {
				name: companyData.name,
				person_name: companyData.person_name,
				email: companyData.email,
			},
			avg: avgChecked,
		};
		toast.promise(sessionService.createSession(body), {
			loading: "Bezig...",
			success: ({ session }) => {
				navigate(`/app/session/${session.id}`);
				return "Nieuwe sessie gestart!";
			},
			error: (error) => {
				setCompaniesLoading(false);
				return error;
			},
		});
	};

	const loadTemplates = () => {
		setLoadingTemplates(true);
		templateService
			.allTemplates(0)
			.then(({ list }) => {
				console.log(list);

				//? check if there are any active templates
				if (list.some((template) => !template.deprecated)) {
					setHasActiveTemplates(true); //? set the state to true
				}
			})
			.catch((error) => {
				toast.error(error);
			})
			.finally(() => {
				setLoadingTemplates(false);
			});
	};

	// automatically select a company if one has been given in base64
	useEffect(() => {
		let urlCompanyData: any = {};

		loadTemplates();

		try {
			const precompany = queryParams.get("precompany");
			if (precompany) {
				const companyObject = JSON.parse(atob(precompany));
				urlCompanyData = companyObject;
			}
		} catch (error) {
			urlCompanyData = null;
		} finally {
			selectCompany(urlCompanyData);
		}
	}, []); //eslint-disable-line react-hooks/exhaustive-deps

	return (
		<div>
			<LogoTopBar
				button={{
					text: "Terug",
					onClick: () => {
						navigate("/app");
					},
					className: "btn-light text-dark",
				}}
			/>
			<FullContainer>
				<Modal
					title="Kies een bedrijf..."
					modalVariant="modal-xl"
					loading={companiesLoading}
					cancelText="Terug"
					onCancel={() => {
						setCompanyModalOpen(false);
						setCompaniesLoading(true);
					}}
					visible={companyModalOpen}>
					<div className="input-group mb-3">
						<input
							type="text"
							className="form-control"
							placeholder="Zoek op naam..."
							name="query"
							value={searchQuery}
							onChange={(e) => setSearchQuery(e.target.value)}
						/>
						<div className="input-group-append">
							<button
								className="btn btn-primary"
								type="submit"
								onClick={searchCompanies}
								style={{
									borderTopLeftRadius: 0,
									borderBottomLeftRadius: 0,
								}}>
								Zoeken
							</button>
						</div>
					</div>

					<table className="table table-hover table-bordered table-responsive table-striped fixed-table">
						<thead>
							<tr>
								<th scope="col">Bedrijf</th>
								<th scope="col">Ondernemer</th>
								<th scope="col">Email</th>
								<th scope="col">Actie</th>
							</tr>
						</thead>
						<tbody>
							{companies.map((company) => {
								return (
									<tr key={company.id}>
										<td className="text-wrap-dots">
											{company.name}
										</td>
										<td className="text-wrap-dots">
											{company.person_name}
										</td>
										<td className="text-wrap-dots">
											{company.email}
										</td>
										<td className="text-wrap-dots">
											<button
												className="btn btn-primary d-block mx-auto w-100"
												onClick={() => {
													selectCompany(company);

													setCompanyModalOpen(false);
												}}>
												Kiezen
											</button>
										</td>
									</tr>
								);
							})}
						</tbody>
					</table>

					{companiesLoading && (
						<p className="text-center text-muted">Laden...</p>
					)}
					{companies.length === 0 && !companiesLoading && (
						<p className="text-center text-muted">
							Geen bedrijven gevonden
						</p>
					)}
				</Modal>

				<form onSubmit={(event) => submitForm(event)}>
					<h1>Nieuwe sessie starten</h1>
					{!loadingTemplates && !hasActiveTemplates ? (
						<div className="alert alert-danger">
							<b>Fout:</b> Er zijn geen actieve sjablonen. Neem
							contact op met een beheerder om er een aan te maken.
						</div>
					) : null}
					<p className="mb-3">
						<strong>Let op:</strong> Zoek eerst of de ondernemer bij
						"
						<span
							className="clickable-text"
							onClick={openCompanyModal}>
							Bestaand bedrijf kiezen
						</span>
						" te vinden is. Vul deze gegevens zorgvuldig in, iemand
						kan deze informatie later nog nodig hebben.
					</p>

					<h3>
						Informatie ondernemer{" "}
						{companyId && <span>(bedrijf geselecteerd)</span>}
					</h3>
					<label htmlFor="personNameInput">
						Naam ondernemer (voor en achternaam)
					</label>
					<input
						name="personNameInput"
						type="text"
						className="form-control mb-3"
						value={companyData.person_name}
						onChange={(e) => {
							setCompanyData({
								...companyData,
								person_name: e.target.value,
							});
						}}
						disabled={companyId !== null}
					/>
					<label htmlFor="companyNameInput">Naam bedrijf</label>
					<input
						name="companyNameInput"
						type="text"
						className="form-control mb-3"
						value={companyData.name}
						onChange={(e) => {
							setCompanyData({
								...companyData,
								name: e.target.value,
							});
						}}
						disabled={companyId !== null}
					/>
					<label htmlFor="emailInput">Email ondernemer</label>
					<input
						name="emailInput"
						type="text"
						className="form-control mb-1"
						value={companyData.email}
						onChange={(e) => {
							setCompanyData({
								...companyData,
								email: e.target.value,
							});
						}}
						disabled={companyId !== null}
					/>
					<label
						htmlFor="avgCheckBox"
						className="form-check-label mb-3">
						<input
							name="avgCheckBox"
							type="checkbox"
							checked={avgChecked}
							onChange={(e) => setAvgChecked(e.target.checked)}
							className="form-check-input"
						/>
						<span className="ms-2">
							Ik ga akkoord met dat mijn gegevens worden
							opgeslagen (AVG)
						</span>
					</label>

					<h3>Informatie sessie </h3>
					<label htmlFor="hostNameInput">Scan uitgevoerd door</label>
					<input
						name="hostNameInput"
						type="text"
						className="form-control mb-3"
						value={companyData.host_names}
						onChange={(e) => {
							setCompanyData({
								...companyData,
								host_names: e.target.value,
							});
						}}
					/>
					<label htmlFor="goalInput">
						Waar wil ik naar toe met mijn bedrijf
					</label>
					<textarea
						name="goalInput"
						rows={3}
						maxLength={2000}
						className="form-control mb-3"
						value={companyData.goal}
						onChange={(e) => {
							setCompanyData({
								...companyData,
								goal: e.target.value,
							});
						}}
					/>

					{!companyId && (
						<button
							type="button"
							className="btn btn-success me-2"
							disabled={loadingTemplates || !hasActiveTemplates}
							onClick={openCompanyModal}>
							Bestaand bedrijf kiezen
						</button>
					)}
					{companyId && (
						<button
							type="button"
							className="btn btn-secondary me-2"
							onClick={() => {
								setCompanyId(null);
								setCompanyData({
									...companyData,
									name: "",
									person_name: "",
									email: "",
								});
							}}>
							Bedrijf deselecteren
						</button>
					)}

					<button
						type="submit"
						className="btn btn-primary"
						disabled={loadingTemplates || !hasActiveTemplates}>
						Bevestigen
					</button>
					{loadingTemplates && (
						<p className="text-muted">
							Sjablonen zijn aan het laden...
						</p>
					)}
				</form>
			</FullContainer>
		</div>
	);
}
