import API, { IApiBareResponse } from ".";
import { IAccount } from "../types/Account";

//? Type definitions
interface ILoginResponse extends IApiBareResponse {
	token: string;
	account: IAccount;
}

//? Service class
class AuthService extends API {
	constructor() {
		super(true, "/auth");
	}

	//? Login
	async login(email: string, password: string, twofa?: string | null) {
		try {
			const response = await this.post<ILoginResponse>(
				"/login",
				{
					email,
					password,
					authCode: twofa,
				},
				true,
			);
			return response;
		} catch (error) {
			throw error;
		}
	}
}

//? Export service
const authService = new AuthService();
export default authService;
