import React, { useState, useEffect } from "react";
import LogoTopBar from "../../components/TopBars/LogoTopBar";
import { useNavigate } from "react-router-dom";
import FullContainer from "../../components/layout/fullContainer";
import { ITheme } from "../../types/Theme";
import themeService from "../../api/theme.service";
import { toast } from "react-hot-toast";
import { isHexDark } from "../../util/hexColor";

export default function NewTheme() {
	const navigate = useNavigate();

	//? States
	const [themes, setThemes] = useState<ITheme[]>([]);
	const [loading, setLoading] = useState<boolean>(false);
	const [loadingPost, setLoadingPost] = useState<boolean>(false);
	//? New theme states
	const [parentThemeId, setParentThemeId] = useState<
		string | null | undefined
	>(null);
	const [name, setName] = useState<string>("");
	const [content, setContent] = useState<string>("");
	const [color, setColor] = useState<string>("");

	const getThemes = async () => {
		//? Get all themes without a parent (all top level themes)
		setLoading(true);
		themeService
			.getParentlessList()
			.then((themes) => {
				setThemes(themes.themes);
			})
			.catch((error) => {
				toast.error(error);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const postTheme = async () => {
		if (!name?.trim() || !content?.trim())
			return toast.error("Naam en inhoud zijn verplicht");

		setLoadingPost(true);
		toast
			.promise(themeService.create(parentThemeId, name, content, color), {
				loading: "De server is bezig met het aanmaken van het thema",
				success: (data) => {
					navigate("/app/manage/themes");
					return "Thema aangemaakt";
				},
				error: (error) => {
					return error;
				},
			})
			.catch((error) => {})
			.finally(() => {
				setLoadingPost(false);
			});
	};

	useEffect(() => {
		getThemes();
	}, []);

	return (
		<div>
			<LogoTopBar
				button={{
					onClick: () => {
						navigate("/app/manage/themes");
					},
					text: "Terug",
					className: "btn-light text-dark",
				}}
			/>
			<FullContainer>
				<h3>Nieuwe Thema</h3>
				<p className="text-muted">
					Selecteer een thema om een nieuw subthema aan te maken
				</p>
				<label htmlFor="parentTheme">Parent Thema</label>
				{loading ? (
					<p className="text-muted" id="parentTheme">
						Loading...
					</p>
				) : (
					<select
						name="parentTheme"
						className="form-control"
						onChange={(e) => {
							setParentThemeId(e.target.value);
						}}>
						<option
							value={undefined}
							selected={parentThemeId === null}>
							Geen
						</option>
						{themes.map((theme) => {
							return (
								<option
									value={theme.id}
									selected={parentThemeId === theme.id}>
									{theme.name}
								</option>
							);
						})}
					</select>
				)}
				<label htmlFor="name" className="mt-2">
					Thema Naam
				</label>
				<input
					type="text"
					name="name"
					placeholder="Naam voor het Thema"
					className="form-control mb-2"
					value={name}
					onChange={(e) => {
						setName(e.target.value);
					}}
				/>
				<label htmlFor="color" className="mt-2">
					Thema Kleur
				</label>
				<input
					type="color"
					name="color"
					className="form-control form-control-color"
					value={color}
					onChange={(e) => {
						setColor(e.target.value);
					}}
				/>

				<label htmlFor="color-font" className="mt-2">
					Thema Font Kleur
				</label>
				<input
					type="color"
					name="color"
					className="form-control form-control-color"
					value={isHexDark(color) ? "#ffffff" : "#000000"}
					disabled
				/>

				<label htmlFor="content" className="mt-2">
					Thema Omschrijving
				</label>
				<textarea
					name="content"
					id=""
					cols={10}
					className="form-control"
					value={content}
					onChange={(e) => {
						setContent(e.target.value);
					}}></textarea>

				<button
					className="btn btn-primary mt-2"
					disabled={loadingPost}
					onClick={postTheme}>
					{loadingPost ? "Bezig..." : "Maak Thema"}
				</button>
			</FullContainer>
		</div>
	);
}
