import React from "react";
import { useNavigate } from "react-router-dom";

interface IProps {
	button?: {
		onClick: () => void;
		text: string;
		className?: string;
	};
	secondButton?: {
		onClick: () => void;
		text: string;
		className?: string;
	};
}

//? LogoTopBar is a component that is used to display the logo in the topbar
export default function LogoTopBar({ button, secondButton }: IProps) {
	const navigate = useNavigate();

	const handleBack = () => {
		// go back if possible else go to home
		if (window.history.length > 2) {
			navigate(-1);
		} else {
			navigate("/app");
		}
	};

	return (
		<div className="topbar w-100 p-2 d-flex justify-content-between align-items-center bg-dw3-green">
			<a href="/app"><img src="/dw3_wit.svg" height={50} alt="" /></a>
			<div className="d-flex flex-row-reverse">
				{button ? (
					<button
						className={`btn btn-light text-dark ${button.className}`}
						onClick={button.onClick}>
						{button.text}
					</button>
				) : (
					<button
						className={`btn btn-light text-dark`}
						onClick={handleBack}>
						Terug
					</button>
				)}
				{secondButton && (
					<button
						className={`btn btn-light text-dark ${secondButton.className}`}
						onClick={secondButton.onClick}>
						{secondButton.text}
					</button>
				)}
			</div>
		</div>
	);
}
