import React from "react";
import ListView from "../components/layout/listView";
import ListCard from "../components/utils/listCard";
import useAuth from "../hooks/useAuth";

// page for testing components and stuff
export default function TestPage() {
	useAuth(true);

	return (
		<ListView backUri="/app">
			<div className="row w-100 m-0">
				<div className="col-6 p-2">
					<ListCard
						className="mb-2"
						titleText="huppekee"
						subText="huppekee(tje)"
						href={() => {
							console.log("HUPPEKEE");
						}}
					/>
					<ListCard
						className="mb-2"
						titleText="huppekee"
						subText="huppekee(tje)"
						href=""
					/>
				</div>
			</div>
		</ListView>
	);
}
