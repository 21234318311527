import React, { useState } from "react";
import "../../styles/priorityCard.css";
import { IPriority } from "../../types/Session";

interface IProps {
	item: IPriority;
	editable?: boolean;
	priorityContents: { [key: string]: string };
	setPriorityContents: (arg: { [key: string]: string }) => void;
}

export default function PriorityCard({
	item,
	editable,
	priorityContents,
	setPriorityContents,
}: IProps) {
	const [content] = useState(item.content);

	return (
		<div
			className={`priorityCard rounded me-2 d-inline-block priority-${item.position}`}
			style={{
				maxHeight: 245,
				minHeight: 245,
				position: "relative",
			}}>
			{item?.extensions?.useSpellcheck &&
				item?.extensions?.spellchecked &&
				!editable && (
					<span
						title="Deze prioriteit is gecontroleerd door de AI"
						className={`badge bg-primary`}
						style={{
							fontSize: 12,
							position: "absolute",
							top: 5,
							right: 5,
						}}>
						AI gecontroleerd
					</span>
				)}
			<div className="p-3">
				<h4
					className="mb-0"
					style={{
						alignItems: "center",
					}}>
					{`Prioriteit ${item.position}`}
				</h4>
				<textarea
					className="form-control w-100 h-100"
					value={
						item?.extensions?.useSpellcheck && !editable
							? item?.extensions?.spellchecked
							: priorityContents?.[item.id] !== undefined
							? priorityContents?.[item.id]
							: content
					}
					disabled={!editable}
					onChange={(e) => {
						setPriorityContents({
							...priorityContents,
							[item.id]: e.target.value,
						} as any);
					}}
					rows={7}></textarea>
			</div>
		</div>
	);
}
