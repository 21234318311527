const validColorRegex = /^#([0-9a-f]{3}){1,2}$/i;

export default function isValidHexColor(color: string) {
	return validColorRegex.test(color);
}

export function isHexDark(color: string) {
	if (!isValidHexColor(color)) return false;

	const hex = color.replace("#", "");
	const c_r = parseInt(hex.substring(0, 0 + 2), 16);
	const c_g = parseInt(hex.substring(2, 2 + 2), 16);
	const c_b = parseInt(hex.substring(4, 4 + 2), 16);
	const brightness = (c_r * 299 + c_g * 587 + c_b * 114) / 1000;

	return brightness < 128;
}
