import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import "../../styles/sessions.css";
import sessionService, {
	ISessionObjectFilter,
} from "../../api/session.service";
import { ISession } from "../../types/Session";
import toast from "react-hot-toast";
import moment from "moment";
import FullContainer from "../../components/layout/fullContainer";
import LogoTopBar from "../../components/TopBars/LogoTopBar";

export default function Sessions() {
	const { actor } = useAuth(true);
	const [queryParams, setQueryParams] = useSearchParams();
	const navigate = useNavigate();

	//? useState hook for the sessions and loading
	const [loading, setLoading] = useState(false);
	const [sessions, setSessions] = useState<ISession[]>([]);
	const [objectFilter, setObjectFilter] = useState<ISessionObjectFilter>(
		ISessionObjectFilter.account_self
	);
	const [objectName, setObjectName] = useState<string | null>(null);

	//? searchSessions function
	const searchSessions = (event?: any) => {
		if (event) event.preventDefault();
		setLoading(true);
		setSessions([]);
		sessionService
			.sessionsFromParams(
				queryParams.get("uid") || actor?.uid!,
				queryParams.get("company_id") || "",
				queryParams.get("search") || ""
			)
			.then((data) => {
				//? set the sessions
				setSessions(data.list);
				setObjectFilter(data.objectFilter);
				setObjectName(data.objectName);
			})
			.catch((error) => {
				toast.error(error);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	//? useEffect hook for getting the sessions
	useEffect(() => {
		searchSessions();
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	let sessieDescriptorText = "Sessies";

	//? switch statement for the sessieDescriptorText
	switch (objectFilter) {
		case ISessionObjectFilter.account_self:
			sessieDescriptorText = "Mijn sessies";
			break;
		case ISessionObjectFilter.account_id:
			sessieDescriptorText = `Sessies van ${objectName || "???"}`;
			break;
		case ISessionObjectFilter.company_id:
			sessieDescriptorText = `Sessies van ${objectName || "???"}`;
			break;
		case ISessionObjectFilter.admin:
			sessieDescriptorText = "Alle sessies";
			break;
		default:
			sessieDescriptorText = "Sessies";
			break;
	}

	return (
		<div>
			<LogoTopBar
				button={{
					onClick: () => {
						navigate("/app");
					},
					text: "Terug",
				}}
			/>
			<FullContainer>
				<div>
					<div className="d-flex justify-content-between mb-2">
						<h4>{sessieDescriptorText}</h4>
						<form
							className="input-group w-50"
							onSubmit={(event) => searchSessions(event)}>
							<input
								type="text"
								className="form-control"
								placeholder="Zoek op bedrijf..."
								value={queryParams.get("search") || ""}
								onChange={(e) => {
									let spObjects: {
										[key: string]: string;
									} = {};
									queryParams.forEach((value, key) => {
										spObjects[key] = value;
									});
									setQueryParams({
										// get the current params
										...spObjects,
										search: e.target.value,
									});
								}}
								disabled={loading}
							/>
							<button
								className="btn btn-outline-secondary"
								type="submit"
								disabled={loading}>
								Zoek
							</button>
						</form>
					</div>
					<table className="table table-hover table-bordered table-responsive table-striped">
						<thead>
							<tr>
								<th scope="col">Bedrijf</th>
								<th scope="col">Ondernemer</th>
								<th scope="col">Afnemer(s)</th>
								<th scope="col">Status</th>
								<th scope="col">Tijd Gestart</th>
								<th scope="col">Actie</th>
							</tr>
						</thead>
						<tbody>
							{sessions.map((session) => {
								let link = `/app/session/${session.id}`;
								if (session?.extensions?.public_key)
									link += `?public_key=${session?.extensions?.public_key}`;
								return (
									<tr key={session.id}>
										<td>{session?.company?.name}</td>
										<td>{session?.company?.person_name}</td>
										<td>{session?.host_names}</td>
										<td>
											{session.editable ? (
												<span className="badge bg-success">
													Bezig
												</span>
											) : (
												<span className="badge bg-secondary">
													Afgerond
												</span>
											)}
										</td>
										<td>
											{moment(session.date).fromNow()}
										</td>
										<td
											// make sure the buttons are always the same size but with a bit of space between
											className="d-flex justify-content-between align-items-center">
											<a
												className="btn btn-outline-primary w-100 me-2"
												href={link}>
												Bekijk Sessie
											</a>
											<a
												className="btn btn-outline-secondary w-100"
												href={`/app/sessions?company_id=${session?.company?.id}`}>
												Vorige Sessies
											</a>
										</td>
									</tr>
								);
							})}
						</tbody>
					</table>

					{loading ? (
						<p className="text-muted">Aan het laden...</p>
					) : sessions?.length === 0 ? (
						<p className="text-muted">Geen sessies gevonden</p>
					) : null}
				</div>
			</FullContainer>
		</div>
	);
}
