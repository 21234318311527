import API, { IApiBareResponse } from ".";
import { ISession, IPriority } from "../types/Session";
import { ITheme } from "../types/Theme";

export enum ISessionObjectFilter {
	account_self,
	account_id,
	company_id,
	admin,
}

//? Type definitions
interface ISessionsResponse extends IApiBareResponse {
	list: ISession[];
	objectFilter: ISessionObjectFilter;
	objectName: string | null;
}

interface ISessionCreateResponse extends IApiBareResponse {
	session: ISession;
}

interface ISessionGetEndpoint extends IApiBareResponse {
	session: ISession;
	themes: ITheme[];
}

interface ISessionUpdatePriority extends IApiBareResponse {
	priority: IPriority;
}

interface ISessionFinish extends IApiBareResponse {
	publicURL: string;
}

interface ICompanyCreateBody {
	name: string;
	person_name: string;
	email: string;
}

export interface ISessionCreateBody {
	company_id: string | null;
	goal: string;
	host_names: string;
	newCompany: ICompanyCreateBody;
	avg: boolean;
}

//? Service class
class SessionService extends API {
	constructor() {
		super(true, "/session");
	}

	//? Get sessions from user
	async sessionsFromParams(
		uid?: string,
		company_id?: string,
		search?: string
	) {
		try {
			const response = await this.get<ISessionsResponse>(
				`/user/zone?uid=${uid}&company_id=${company_id}&q=${
					search || ""
				}`
			);
			return response;
		} catch (error) {
			throw error;
		}
	}

	async getSession(sid: string, publicKey?: string) {
		try {
			const response = await this.get<ISessionGetEndpoint>(
				`/${sid}?public_key=${publicKey || "none"}`
			);
			return response;
		} catch (error) {
			throw error;
		}
	}

	async createSession(body: ISessionCreateBody) {
		try {
			const response = await this.post<ISessionCreateResponse>(
				`/create`,
				{
					company_id: body.company_id,
					goal: body.goal,
					host_names: body.host_names,
					newCompany: {
						name: body.newCompany.name,
						person_name: body.newCompany.person_name,
						email: body.newCompany.email,
					},
					avg: body.avg,
				}
			);
			return response;
		} catch (error) {
			throw error;
		}
	}

	async bulkUpdatePriority(
		session_id: string,
		priorities: {
			[priority_id: string]: string;
		}
	) {
		try {
			const response = await this.put<IApiBareResponse>(
				`/${session_id}/priority/bulk`,
				{
					priorities,
				}
			);
			return response;
		} catch (error) {
			throw error;
		}
	}

	async updatePriority(priority_id: string, content: string) {
		try {
			const response = await this.put<ISessionUpdatePriority>(
				`/priority/${priority_id}`,
				{ content }
			);
			return response;
		} catch (error) {
			throw error;
		}
	}

	async finish(session_id: string, followup_option: string) {
		try {
			const response = await this.post<ISessionFinish>(
				`/${session_id}/finish`,
				{
					followup_option,
				}
			);
			return response;
		} catch (error) {
			throw error;
		}
	}
}

//? Export service
const sessionService = new SessionService();
export default sessionService;
