import React, { useState, useEffect } from "react";
import ListView from "../../components/layout/listView";
import useAuth from "../../hooks/useAuth";
import templateService from "../../api/template.service";
import { ITemplate } from "../../types/Template";
import toast from "react-hot-toast";
import moment from "moment";
import Modal from "../../components/Modal/Modal";
import { ITheme } from "../../types/Theme";
import { ExampleTheme } from "../../components/Session/ExampleTheme";
import themeService from "../../api/theme.service";

export default function Templates() {
	useAuth(true);
	const [templates, setTemplates] = useState<ITemplate[]>([]);
	const [page, setPage] = useState(0);
	const [detailTemplate, setDetailTemplate] = useState<ITemplate | null>(
		null
	);
	const [detailThemes, setDetailThemes] = useState<ITheme[] | null>(null);
	const [expandTheme, setExpandTheme] = useState<ITheme | null>(null);

	const getTemplates = () => {
		templateService
			.allTemplates(page)
			.then((data) => {
				//? set the templates
				setTemplates((p) => [...p, ...data.list]);
				setPage((p) => p + 1);
			})
			.catch((error) => {
				toast.error(error);
			});
	};

	const getDetailThemes = (template_id: number) => {
		themeService
			.getByTemplate(template_id)
			.then(({ themes }) => {
				setDetailThemes(themes);
			})
			.catch((err) => {
				toast.error(err);
			});
		return;
	};

	//? useEffect hook for getting the templates
	useEffect(() => {
		getTemplates();
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<ListView backUri="/app">
			<Modal
				title="Bekijk Thema's"
				cancelText="Sluiten"
				visible={expandTheme !== null}
				modalVariant="modal-xl"
				onCancel={() => {
					setExpandTheme(null);
				}}>
				<div className="sideways-themes-items">
					<ExampleTheme item={expandTheme!} />
					{(expandTheme?.theme.length ?? 0) > 0 && (
						<>
							<div className="sideway-theme-divider" />
							<div className="sideway-theme-list">
								{expandTheme?.theme.map((item) => {
									return (
										<ExampleTheme
											key={item.id}
											item={item}
										/>
									);
								})}
							</div>
						</>
					)}
				</div>
			</Modal>
			<div className="row w-100 m-0">
				<div className="col-6 p-2">
					<div className="d-flex justify-content-between mb-2">
						<h3>Templates</h3>
						<a
							href="/app/templates/create"
							className="btn btn-primary mb-2">
							Nieuw Template
						</a>
					</div>

					<table className="table table-hover table-bordered table-responsive table-striped">
						<thead>
							<tr>
								<th scope="col">ID</th>
								<th scope="col">Status</th>
								<th scope="col">Datum</th>
								<th scope="col">Actie</th>
							</tr>
						</thead>
						<tbody>
							{templates.map((item) => {
								return (
									<tr key={item.id}>
										<th scope="row">{item.id}</th>
										<td>
											{item.deprecated ? (
												<span className="badge bg-secondary">
													Verouderd
												</span>
											) : (
												<span className="badge bg-success">
													Actief
												</span>
											)}
										</td>
										<td>
											{moment(item.date).format(
												"MMM Do YYYY, h:mm:ss"
											)}
										</td>
										<td>
											<button
												className="btn btn-dark w-100"
												onClick={() => {
													setDetailTemplate(item);
													getDetailThemes(item.id);
												}}>
												Bekijk
											</button>
										</td>
									</tr>
								);
							})}
						</tbody>
					</table>
				</div>
				<div className="col-6 p-2 mb-2">
					<div
						className="d-flex flex-column justify-content-start w-100"
						style={{
							borderLeft: "3px solid #dee2e6",
							paddingLeft: "1rem",
							top: 10,
							position: "sticky",
						}}>
						{detailTemplate ? (
							<div className="user-opened-view">
								<table className="table table-hover table-bordered table-striped">
									<thead
										style={{
											display: "none",
										}}>
										<tr>
											<th scope="col"></th>
											<th scope="col"></th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<th scope="row">ID</th>
											<td>{detailTemplate.id}</td>
										</tr>
										<tr>
											<th scope="row">Status</th>
											<td>
												{detailTemplate.deprecated ? (
													<span className="badge bg-secondary">
														Verouderd
													</span>
												) : (
													<span className="badge bg-success">
														Actief
													</span>
												)}
											</td>
										</tr>
										<tr>
											<th scope="row">
												Datum Aangemaakt
											</th>
											<td>
												{moment(
													detailTemplate.date
												).fromNow()}{" "}
												(
												{moment(
													detailTemplate.date
												).format(
													"MMM Do YYYY, h:mm:ss"
												)}
												)
											</td>
										</tr>
									</tbody>
								</table>
								{/* this is a flex container in case more buttons need to be added */}
								<div className="accountDetailButtonRow w-100"></div>
								<h3>Thema's:</h3>
								<div className="themeRow d-flex flex-wrap">
									{detailThemes ? (
										detailThemes.map((item) => {
											// only render themes without parent
											if (!item.parent_theme_id) {
												return (
													<div className="m-1 mb-3">
														<div
															className="p-2 rounded d-inline btn"
															onClick={() => {
																setExpandTheme(
																	item
																);
															}}
															style={{
																background:
																	item
																		.extensions
																		.color ||
																	"var(--light-gray)",
																color: item
																	?.extensions
																	?.usesDarkFont
																	? "black"
																	: "white",
															}}>
															{item.name}
														</div>
													</div>
												);
											}
											return null;
										})
									) : (
										<p className="text-muted">Laden...</p>
									)}
								</div>
							</div>
						) : (
							<div className="user-closed-view">
								<h4>
									Klik op een template (Bekijk knop) om de
									details te bekijken
								</h4>
							</div>
						)}
					</div>
				</div>
			</div>
		</ListView>
	);
}
