import React from "react";
import { RecoilRoot } from "recoil";
import { Toaster } from "react-hot-toast";
import { ReactFlowProvider } from "reactflow";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

//? Import pages
import Login from "./Pages/Login/Login";
import Home from "./Pages/Menu/Home";
import Sessions from "./Pages/Menu/Sessions";
import Session from "./Pages/Session/Session";
import TestPage from "./Pages/TestPage";
import Templates from "./Pages/Template/Templates";
import Users from "./Pages/Manage/Users";
import NotFoundErrorPage from "./Pages/Error/notFoundErrorPage";
import DeveloperOverlay from "./components/Overlays/DeveloperOverlay";
import HttpStatsOverlay from "./components/Overlays/HttpStatsOverlay";
import ThemeManagement from "./Pages/Theme/ThemeManagement";
import NewTheme from "./Pages/Theme/NewTheme";
import NewUser from "./Pages/Manage/NewUser";
import CreateTemplate from "./Pages/Template/CreateTemplate";
import CompanyDataForm from "./Pages/Session/CompanyDataForm";
import Company from "./Pages/Company/Company";
import SessionSummary from "./Pages/Session/SessionSummary";
import ChangePassword from "./Pages/Login/ChangePassword";

//? Set moment locale
import moment from "moment";
import "moment/locale/nl";
moment.locale("nl");

//? Create the router
const router = createBrowserRouter([
	{ path: "/test", element: <TestPage /> },
	{ path: "/", element: <Login /> },
	{ path: "/login", element: <Login /> },
	{ path: "/app", element: <Home /> },
	{ path: "/app/sessions", element: <Sessions /> },
	{ path: "/app/new-session", element: <CompanyDataForm /> },
	{ path: "/app/session/:session_id", element: <Session /> },
	{ path: "/app/session/:session_id/summary", element: <SessionSummary /> },
	{ path: "/app/templates", element: <Templates /> },
	{ path: "/app/templates/create", element: <CreateTemplate /> },
	{ path: "/app/manage/themes", element: <ThemeManagement /> },
	{ path: "/app/manage/themes/new", element: <NewTheme /> },
	{ path: "/app/manage/users", element: <Users /> },
	{ path: "/app/manage/users/create", element: <NewUser /> },
	{ path: "/app/manage/companies", element: <Company /> },
	{ path: "/app/changepassword", element: <ChangePassword /> },
	{ path: "*", element: <NotFoundErrorPage /> },
]);

//? App function
function App() {
	return (
		<RecoilRoot>
			<ReactFlowProvider>
				<RouterProvider router={router} />
			</ReactFlowProvider>
			<DeveloperOverlay />
			<HttpStatsOverlay />
			<Toaster />
		</RecoilRoot>
	);
}

export default App;
