import API, { IApiBareResponse } from ".";
import { IAvgCompliance } from "../types/Avg";

interface IComplianceResponse extends IApiBareResponse {
	compliance: IAvgCompliance;
}

//? Service class
class AvgService extends API {
	constructor() {
		super(true, "/avg");
	}

	//? Get AVG compliance object
	async getCompliance(email: string) {
		try {
			const response = await this.get<IComplianceResponse>(
				`/compliance?email=${encodeURIComponent(email)}`
			);
			return response;
		} catch (error) {
			throw error;
		}
	}
}

//? Export service
const avgService = new AvgService();
export default avgService;
