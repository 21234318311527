import React, { useState } from "react";
import useAuth from "../../hooks/useAuth";
import FullContainer from "../../components/layout/fullContainer";
import LogoTopBar from "../../components/TopBars/LogoTopBar";
import toast from "react-hot-toast";
import accountService from "../../api/account.service";
import { useNavigate } from "react-router-dom";

export default function NewUser() {
	//? hooks
	useAuth(true);
	const [email, setEmail] = useState<string>("");
	const [nickname, setNickname] = useState<string>("");
	const [loading, setLoading] = useState<boolean>(false);
	const navigate = useNavigate();

	//? create toast promise to create new user
	const createUser = (event: any) => {
		event.preventDefault();
		setLoading(true);
		toast.promise(accountService.createAccount(nickname, email), {
			loading: "Bezig...",
			success: () => {
				navigate("/app/manage/users");
				return "Account gemaakt!";
			},
			error: (error) => error,
		});
		setLoading(false);
	};

	return (
		<div>
			<LogoTopBar
				button={{
					text: "Terug",
					onClick: () => navigate("/app/manage/users"),
					className: "btn-light text-dark",
				}}
			/>
			<FullContainer>
				<h3>Nieuw account maken</h3>
				<form onSubmit={(event) => createUser(event)}>
				<label htmlFor="emailInputField" className="form-label">
					Accountnaam
				</label>
				<input
					type="text"
					className="form-control mb-3"
					id="nicknameInputField"
					value={nickname}
					onChange={(e) => setNickname(e.target.value)}
				/>

				<label htmlFor="emailInputField" className="form-label">
					Email-adres
				</label>
				<input
					type="email"
					className="form-control mb-3"
					id="emailInputField"
					value={email}
					onChange={(e) => setEmail(e.target.value)}
				/>

				<button
					type="submit"
					className="btn btn-primary"
					disabled={loading}>
					{loading ? "Bezig..." : "Account aanmaken"}
				</button>
				</form>
			</FullContainer>
		</div>
	);
}
