import React from "react";
import { useNavigate } from "react-router-dom";
import "../../styles/listView.css";
import LogoTopBar from "../TopBars/LogoTopBar";

//? ListView is a component that is used to center the content of a page
interface IProps {
	children?: React.ReactNode;
	backUri: string;
}

export default function ListView({ children, backUri }: IProps) {
	const navigate = useNavigate();

	const _onBack = () => {
		navigate(backUri);
	};

	return (
		<div className="wrapper w-100 h-100">
			<LogoTopBar
				button={{
					text: "Terug",
					onClick: _onBack,
					className: "btn-light text-dark",
				}}
			/>
			{/* <div className="topbar w-100 p-3 d-flex justify-content-between align-items-center">
				<h1 className="mb-0">{topBarText}</h1>
				<button onClick={_onBack} className="btn btn-light">
					Terug
				</button>
			</div> */}
			<div className="childrenContainer w-100">{children}</div>
		</div>
	);
}
