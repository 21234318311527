import React, { useEffect, useState, useRef } from "react";
import ReactFlow, { Controls, Background, NodeProps, Panel } from "reactflow";
// we have to import the React Flow styles for it to work
import "../../styles/themesSection.css";
import "reactflow/dist/style.css";

import { ITheme } from "../../types/Theme";
import { ISession } from "../../types/Session";

interface Props {
	session: ISession;
	themes: ITheme[];
	onCompanyInfoClick: () => void;
	onThemeExpand: (theme: ITheme) => void;
	noteContents: { [key: string]: string };
}

//? interface for themes that are rendered
interface IEdge {
	id: string;
	source: string;
	target: string;
}

interface IFlowNode {
	id: string;
	type: string;
	position: {
		x: number;
		y: number;
	};
	data: {
		label: string;
		backgroundColor?: string;
		theme: ITheme;
		hasNoteContent: boolean;
	};
}

function ThemeNode(props: NodeProps<any>) {
	return (
		<div
			className="themeCustomNode"
			style={{
				backgroundColor: props?.data?.theme?.extensions?.color,
				cursor: "pointer",
				color: props?.data?.theme?.extensions?.usesDarkFont
					? "black"
					: "white",
			}}>
			<div className="themeCustomNodeInner">
				{props?.data?.hasNoteContent && (
					<div
						className="has-note-icon"
						style={{
							borderColor: props?.data?.theme?.extensions?.color,
						}}></div>
				)}
				<div className="themeNode__title">
					<p>{props?.data?.theme?.name}</p>
					<span className="themeNode__title subtitle">
						{props?.data?.theme?.theme?.length} subthema's
					</span>
				</div>
			</div>
		</div>
	);
}

const nodeTypes = {
	themeNode: ThemeNode,
};

export default function ThemesSection({
	themes,
	session,
	onCompanyInfoClick,
	onThemeExpand,
}: Props) {
	// const [edges, setEdges] = useState<IEdge[]>([]);
	const [nodes, setNodes] = useState<IFlowNode[]>([]);
	const flowRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		// transform themes to nodes
		const newNodes: IFlowNode[] = [];
		const newEdges: IEdge[] = [];

		const radius = 245;
		const step = (2 * Math.PI) / themes.length;
		var angle = Math.PI * 3.5;

		const width = flowRef.current?.clientWidth || 0;
		const height = flowRef.current?.clientHeight || 0;

		themes.forEach((theme, index) => {
			const posX = Math.round(
				width / 2 + radius * Math.cos(angle) - window.innerWidth / 1.75
			);
			const posY = Math.round(
				height / 2 + radius * Math.sin(angle) - window.innerHeight / 2
			);

			const position = {
				x: posX,
				y: posY,
			};

			angle += step;

			let hasNotes = false;

			//? Logic to display the "has notes" icon
			if (theme?.theme_notes?.[0]?.content?.length > 0) {
				hasNotes = true;
			} else if (theme?.theme?.length > 0) {
				//? CHeck the subthemes
				theme?.theme?.forEach((subTheme) => {
					if (subTheme?.theme_notes?.[0]?.content?.length > 0) {
						hasNotes = true;
					}
				});
			}

			newNodes.push({
				id: theme.id,
				type: "themeNode",
				position,
				data: {
					label: theme.name,
					backgroundColor: theme?.extensions?.color,
					theme,
					hasNoteContent: hasNotes,
				},
			});

			// get previous theme
			const previousTheme = themes[index - 1];

			// if previous theme exists, add edge
			if (previousTheme) {
				newEdges.push({
					id: `${previousTheme.id}-${theme.id}`,
					source: previousTheme.id,
					target: theme.id,
				});
			}
		});

		// edge from last theme to end
		const lastTheme = themes?.[themes.length - 1];
		const firstTheme = themes?.[0];

		newEdges.push({
			id: `${lastTheme?.id}-${firstTheme?.id}`,
			source: lastTheme?.id,
			target: firstTheme?.id,
		});

		// set nodes and edges
		setNodes(newNodes);
	}, [themes]);

	return (
		<div
			ref={flowRef}
			style={{
				height: "500px",
			}}>
			<ReactFlow
				nodes={nodes}
				// edges={edges}
				fitView
				fitViewOptions={
					{
						// padding: 0.2,
					}
				}
				proOptions={{
					hideAttribution: true,
				}}
				nodeTypes={nodeTypes}
				onNodeClick={(event, node) => {
					onThemeExpand(node?.data?.theme);
				}}>
				<Background />
				<Controls showInteractive={false} />
				<Panel
					position={"top-left"}
					className="d-flex justify-content-center">
					<div className="session-panel-top-left">
						<h3>{session?.company?.name || "Onbekend"}</h3>
						<p>{session?.company?.person_name || "Onbekend"}</p>
					</div>
					<div
						style={{
							marginLeft: 5,
						}}>
						{!session?.editable && (
							<div
								className="badge bg-danger text-center d-block"
								style={{
									marginBottom: 5,
								}}>
								Beëindigd
							</div>
						)}
						<button
							className="btn btn-light"
							onClick={onCompanyInfoClick}>
							Informatie
						</button>
					</div>
				</Panel>
			</ReactFlow>
		</div>
	);
}
