import React from "react";
import "../../styles/listCard.css";
import { useNavigate } from "react-router-dom";

//? ListCard is a component that is used to display a card in a list
interface CardProps {
	href?: string | (() => void);
	titleText?: string;
	subText?: string;
	className?: string;
}

export default function ListCard({
	href,
	titleText,
	subText,
	className,
}: CardProps) {
	const navigate = useNavigate();
	const _hanleClick = () => {
		if (!href) return;
		if (typeof href === "string") {
			navigate(href);
		} else {
			href();
		}
	};

	let clickable = "";
	if (typeof href !== "undefined") {
		clickable = "clickable";
	}

	return (
		<div
			onClick={_hanleClick}
			className={`listCardWrapper p-2 rounded ${clickable} ${className}`}>
			<div className="listCard">
				<h5 className="cardTitle">{titleText}</h5>
				<p className="cardSubtext">{subText}</p>
			</div>
		</div>
	);
}
